import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, deleteUser } from "../../actions/users";
import UserDataService from "../../services/UserService";
import { useParams } from "react-router-dom";
import avatarImage1 from '../../assets/images/avatars/ONI1.png';
import avatarImage2 from '../../assets/images/avatars/ONI2.png';
import avatarImage3 from '../../assets/images/avatars/ONI3.png';
import avatarImage4 from '../../assets/images/avatars/ONI4.png';
// import avatarImage5 from '../../assets/images/avatars/ONI1.png';
// import avatarImage6 from '../../assets/images/avatars/ONI1.png';


const User = (props) => {


  // let { id } = useParams(); 
  const stateUser = useSelector(state => state.auth.user);

  const initialUserState = {
    email: stateUser.email,
    password: "",
    active: false
  };




  const [currentUser, setCurrentUser] = useState(initialUserState);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  
  // const getUser = id => {
  //   UserDataService.get(id)
  //     .then(response => {
  //       setCurrentUser(response.data);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };
  // useEffect(() => {
  //   getUser(id);
  // }, [id]);
  
  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };
  const updateContent = () => {
    dispatch(updateUser(currentUser._id, currentUser))
      .then(response => {
        setMessage("The user was updated successfully!");
      })
      .catch(e => {
        console.log(e);
      });
  };
  // const removeUser = () => {
  //   dispatch(deleteUser(currentUser.id))
  //     .then(() => {
  //       props.history.push("/users");
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  const profileImageSelected = (param) => {
    console.log( param );
  };

  return (
    <div>
      {/* {currentUser ? (
        <div className="edit-form">
          <h4>User</h4>
          <form>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={currentUser.email}
                onChange={handleInputChange}
                aria-describedby="emailHelp"
              />
              <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Password</label>
              <input
                type="text"
                className="form-control"
                id="password"
                name="password"
                value={currentUser.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" id="active" defaultChecked={currentUser.active} />
              <label className="form-check-label" htmlFor="active">Active</label>
            </div>
          </form>
          <button className="btn btn-primary" onClick={removeUser}>
            Delete
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={updateContent}
          >
            Update
          </button>
          {message === '' ? ('') : (
            <div className="alert alert-warning alert-dismissible fade show" role="alert">
            {message}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a User...</p>
        </div>
      )} */}





<section className="container">
        <div className="row">
          
          <aside className="col-lg-3 col-md-4 border-end mt-n5">
            <div className="position-sticky top-0">
              <div className="text-center pt-5">
                <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                  <img src={avatarImage4} className="d-block rounded-circle" width="120" alt="John Doe" />
                  {/* <button type="button" className="btn btn-icon btn-light bg-white btn-sm border rounded-circle shadow-sm position-absolute bottom-0 end-0 mt-4" data-bs-toggle="tooltip" data-bs-placement="bottom" aria-label="Change picture" data-bs-original-title="Change picture">
                    <i className="bx bx-refresh"></i>
                  </button> */}

                  <button type="button" className="btn btn-icon btn-light bg-white btn-sm border rounded-circle shadow-sm position-absolute bottom-0 end-0 mt-4" data-bs-toggle="modal" data-bs-target="#modalId">
                    <i className="bx bx-refresh"></i>
                  </button>
                </div>
                {/* <h2 className="h5 mb-1">John Doe</h2>
                <p className="mb-3 pb-3">jonny@email.com</p> */}
                <button type="button" className="btn btn-secondary w-100 d-md-none mt-n2 mb-3 collapsed" data-bs-toggle="collapse" data-bs-target="#account-menu" aria-expanded="false">
                  <i className="bx bxs-user-detail fs-xl me-2"></i>
                  Account menu
                  <i className="bx bx-chevron-down fs-lg ms-1"></i>
                </button>
                <div id="account-menu" className="list-group list-group-flush d-md-block collapse" >
                  <a href="account-details.html" className="list-group-item list-group-item-action d-flex align-items-center active">
                    <i className="bx bx-cog fs-xl opacity-60 me-2"></i>
                    Accountt Details
                  </a>
                  <a href="account-security.html" className="list-group-item list-group-item-action d-flex align-items-center">
                    <i className="bx bx-lock-alt fs-xl opacity-60 me-2"></i>
                    Security
                  </a>
                  <a href="account-saved-items.html" className="list-group-item list-group-item-action d-flex align-items-center">
                    <i className="bx bx-bookmark fs-xl opacity-60 me-2"></i>
                    Saved Items
                  </a>
                  <a href="account-signin.html" className="list-group-item list-group-item-action d-flex align-items-center">
                    <i className="bx bx-log-out fs-xl opacity-60 me-2"></i>
                    Sign Out
                  </a>
                </div>
              </div>
            </div>
          </aside>


          
          <div className="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
            <div className="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
              <h1 className="h2 pt-xl-1 pb-3">Account Details</h1>

              
              <h2 className="h5 text-primary mb-4">Basic info</h2>
              <form className="needs-validation border-bottom pb-3 pb-lg-4" noValidate="">
                <div className="row pb-2">
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="fn" className="form-label fs-base">Full name</label>
                    <input type="text" id="fn" className="form-control form-control-lg" required="" />
                    <div className="invalid-feedback">Please enter your first name!</div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="email" className="form-label fs-base">Email address</label>
                    <input type="email" 
                            id="email" 
                            className="form-control form-control-lg" 
                            value={currentUser.email}
                            onChange={handleInputChange} />
                    <div className="invalid-feedback">Please provide a valid email address!</div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="country" className="form-label fs-base">Languaje</label>
                    <select id="country" className="form-select form-select-lg" required="">
                      <option value="" disabled="">Choose languaje</option>
                      <option value="Australia">English</option>
                      <option value="Belgium">Español</option>
                    </select>
                    <div className="invalid-feedback">Please choose your country!</div>
                  </div>
                  <div className="col-sm-6 mb-4">
                    <label htmlFor="phone" className="form-label fs-base">Phone <small className="text-muted">(optional)</small></label>
                    <input type="text" id="phone" className="form-control form-control-lg" data-format="{&quot;numericOnly&quot;: true, &quot;delimiters&quot;: [&quot;+1 &quot;, &quot; &quot;, &quot; &quot;], &quot;blocks&quot;: [0, 3, 3, 2]}" placeholder="+1 ___ ___ __" />
                  </div>
                  <div className="col-12 mb-4">
                    <label htmlFor="bio" className="form-label fs-base">Bio <small className="text-muted">(optional)</small></label>
                    <textarea id="bio" className="form-control form-control-lg" rows="4" placeholder="Add a short bio..."></textarea>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <button type="reset" className="btn btn-secondary me-3">Cancel</button>
                  <button type="submit" className="btn btn-primary">Save changes</button>
                </div>
              </form>

              
              <h2 className="h5 text-primary pt-1 pt-lg-3 mt-4">Delete account</h2>
              <p>When you delete your account, your public profile will be deactivated immediately. If you change your mind before the 14 days are up, sign in with your email and password, and we’ll send you a link to reactivate your account.</p>
              <div className="form-check mb-4">
                <input type="checkbox" id="delete-account" className="form-check-input" />
                <label htmlFor="delete-account" className="form-check-label fs-base">Yes, I want to delete my account</label>
              </div>
              <button type="button" className="btn btn-danger">Delete</button>
            </div>
          </div>
        </div>
      </section>


      <div className="modal fade" id="modalId" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Select new profile image</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div className="container">
              <div className="row">
                <div className="col-4">



                  <div className="zoom-effect-wrapper rounded-5">
                    <div className="zoom-effect-img">
                      <a href="#" onClick={ () => profileImageSelected("ONI1.png") } >
                        <img src={avatarImage1} className="rounded-4" alt="Image" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="zoom-effect-wrapper rounded-5">
                    <div className="zoom-effect-img">
                    <a href="#" onClick={ () => profileImageSelected("ONI2.png") } >
                        <img src={avatarImage2} className="rounded-4" alt="Image" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="zoom-effect-wrapper rounded-5">
                    <div className="zoom-effect-img">
                      <a href="#" onClick={ () => profileImageSelected("ONI3.png") }>
                        <img src={avatarImage3} className="rounded-4" alt="Image" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="zoom-effect-wrapper rounded-5">
                    <div className="zoom-effect-img">
                      <a href="#" onClick={ () => profileImageSelected("ONI4.png") } >
                        <img src={avatarImage4} className="rounded-4" alt="Image" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="zoom-effect-wrapper rounded-5">
                    <div className="zoom-effect-img">
                      <a href="#" onClick={ () => profileImageSelected("ONI1.png") } >
                        <img src={avatarImage1} className="rounded-4" alt="Image" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="zoom-effect-wrapper rounded-5">
                    <div className="zoom-effect-img">
                      <a href="#" onClick={ () => profileImageSelected("ONI2.png") } >
                          <img src={avatarImage2} className="rounded-4" alt="Image" />
                          </a>
                      </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default User;