import { flatMap } from "lodash";
import {
    RETRIEVE_PRACTICE_VERB,
    SET_PRACTICE_VERB_RESULT,
    SET_PRACTICE_VERB_RESPONSE,
    RETRIEVE_VERB_BY_LEVEL,
    SET_STUDIED_VERB,
    LIKE_VERB,
    UNLIKE_VERB,
    GET_VERB_PROGRESS
  } from "../actions/types";

import{
  VERB_FORMS,
  FORMAT_PROGRESS
} from "../globalConstants";
  
const initialState = { 
    all: {
      list:[], 
      jlpt:5
    }, 
    study:{}, 
    progress:{
      level:1, 
      percentage: 0,
      correct: 0,
      total: 0
    }
  };


function formatStudyVerb(payload){
  // const formIndex = Math.floor(Math.random() * (forms.length));
  let form = "formal";
  let minScore = payload["formal_pct"];

  VERB_FORMS.forEach((f) => {

    if(payload[f+"_pct"] < minScore){
      form = f;
      minScore = payload[f+"_pct"];
    }

  });  

  payload.studied = payload.correct > 0;

  const studyObj = {
    verb: payload,
    form: form,
    result: null,
    answer: ""
  };

  return studyObj;
}

function verbReducer(verb = initialState, action) {
  
  
    const { type, payload } = action;

    switch (type) {
      case RETRIEVE_PRACTICE_VERB:
        return Object.assign({}, verb, {
          study: formatStudyVerb(payload)
        })
      case RETRIEVE_VERB_BY_LEVEL:
        return Object.assign({}, verb, {
          all: {list: payload.data, jlpt:payload.jlpt}
        })
      case SET_PRACTICE_VERB_RESULT:
        const resultObj = Object.assign({}, verb.study, { result: payload })

        return Object.assign({}, verb, {
          study: resultObj
        })
      case SET_PRACTICE_VERB_RESPONSE:
        const answerObj = Object.assign({}, verb.study, { answer: payload })

        return Object.assign({}, verb, {
          study: answerObj
        })
      case SET_STUDIED_VERB: 
        const questionObj = Object.assign({}, verb.study.verb, { studied: payload })
        const studiedObj = Object.assign({}, verb.study, { verb: questionObj})

        return Object.assign({}, verb, {
          study: studiedObj
        })
      case LIKE_VERB: 
        var verb_list = verb.all.list;
  
        //refactor this
        verb_list.forEach((element, index) => {
          if(element.id === payload.likeable_id) {
            verb_list[index].liked = true;
          }
        });
  
        var verb_all_obj = Object.assign({}, verb.all, { list: [...verb_list] })
  
        return Object.assign({}, verb, {
          all: verb_all_obj
        })
        
      case UNLIKE_VERB: 
        var verb_list = verb.all.list;

        //refactor this
        verb_list.forEach((element, index) => {
          if(element.id === payload.likeable_id) {
            verb_list[index].liked = false;
          }
        });

        var verb_all_obj = Object.assign({}, verb.all, { list: [...verb_list] })


        return Object.assign({}, verb, {
          all: verb_all_obj
        })
      case GET_VERB_PROGRESS:
        return Object.assign({}, verb, {
          progress: FORMAT_PROGRESS(payload)
        })
      default:
        return verb;
    }
  };

  export default verbReducer;