import React, { useEffect, useRef } from 'react';
import HiraganaInput from '../verb/HiraganaInput';
import StudyResultText from './StudyResultText';



function TextStudyCard(props) {

  const nextButtonRef = useRef();
  const answerRef = useRef();

  useEffect(() => {

    if(props.result != null){
        nextButtonRef.current.focus();
    }
  }, [props.result]);

  return (
    <div>
    
    <div className="container">
      <div className="row">
        <div className="col col-lg-6 offset-lg-3">
          <div className="card">
            <h5 className="card-header">
                {props.header}
            </h5>
            <div className="card-body">
                <div className='d-flex justify-content-end'>
                    <span className={"badge fs-s " + props.badgeClass}>{props.badgeText}</span>
                </div>
                
                <h1 className="display-4">{props.kanji}</h1>
                {/* <p className="card-text text-muted">{props.meaning}</p>
                 */}
                 <br/>
                <div className="container">
                  
                 {props.result == null &&
                  <div>
                    <div className="row top-buffer">
                    <div className="col">
                        <HiraganaInput handleAnswer={props.handleAnswer} answerRef={answerRef} />
                    </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <br/>
                        <button
                          type="submit"
                          className="btn btn-outline-primary float-end"
                          onClick={props.checkAnswer}
                        >Check Answer</button>
                      </div>
                    </div>
                  </div>
                }


                {props.result != null &&
                  <div>
                    <StudyResultText result={props.result} kanji={props.kanji} correctAnswer={props.correctAnswer} meaning={props.meaning} />

                    <button
                        type="submit"
                        className="btn btn-outline-primary float-end"
                        onClick={props.nextStudy}
                        ref={nextButtonRef}
                      >Next Question
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
  );
}

export default TextStudyCard;
