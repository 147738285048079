import http from "../http-common";
import store from '../store';

const like = data => {
  let state = store.getState();
  let token = state.auth.user.access_token;
  
  if(token != undefined){
    return http.post("/api/likes", data, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    });
  }
};

const unlike = data => {
  let state = store.getState();
  let token = state.auth.user.access_token;

  if(token != undefined){
    return http.delete(`/api/likes/${data.like.likeable_id}`, 
        { data, headers: {'Authorization': `Bearer ${token}` } });
  }
};

const getAll = () => {
  let state = store.getState();
  let token = state.auth.user.access_token;
  
  return http.get(`/api/likes`, {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
};

const LikeService = {
  like,
  unlike,
  getAll
};
export default LikeService;
