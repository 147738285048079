import React, { useEffect } from "react";

const ItemLearnedCard = props => {
    let tryItButton = null;
    
    useEffect(()=>{
        tryItButton.focus();
    })

    return (
        <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-12">
                        <div className={"card border-primary"}>
                            <div className="card-body">
                                <h3 className="text-center text-primary">Congratulations!! </h3>
                                <p className={"text-center fs-4 text-primary"}>You just learned</p>
                                <p className={"text-center fs-4 text-primary"}></p>
                                <h1 className={"display-1 text-center text-primary card-title"}>{props.kanji === "" ? props.pronunciation1 : props.kanji}</h1>
                                <p className={"text-center fs-6 text-primary"}>{props.levelCorrect} from {props.levelTotal} items learned on Level {props.level}</p>

                            </div>
                            <div className="card-footer border-primary fs-sm text-muted">
                                <button type="button" className="btn btn-primary w-100" onClick={props.next} ref={(button) => { tryItButton = button; }}>
                                    <i className="ai-emoji-smile me-2"></i>
                                    Continue Learning!
                                </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        );
  
};

export default ItemLearnedCard;