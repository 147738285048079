import _ from "lodash";
import {
    RETRIEVE_VOCABULARY, 
    RETRIEVE_VOCABULARY_BY_LEVEL,
    RETRIEVE_STUDY_VOCABULARY,
    SET_STUDY_VOCABULARY_OPTIONS_RESULT,
    SET_STUDIED_VOCABULARY,
    LIKE_VOCABULARY,
    UNLIKE_VOCABULARY,
    GET_VOCABULARY_PROGRESS,
    VOCABULARY_LEVEL_CHANGE_SHOWED,
    VOCABULARY_JUST_LEARNED
  } from "../actions/types";

  import{
    FORMAT_PROGRESS,
    DEFAULT_VOCABULARY_OPTIONS
    } from "../globalConstants";

  const initialState = { 
      all: {
          list:[], 
          jlpt:5
        }, 
        study:{}, 
        progress:{
          level:1, 
          percentage: 0, 
          showNewLevelCard: false,
          correct: 0,
          total: 0
        }
      };

  function formatStudyVocabulary(payload){

    let studied = payload[0].correct > 0;
    payload[0].studied = studied;

    let vocabOptions = DEFAULT_VOCABULARY_OPTIONS();
    let allOptions = payload.concat(vocabOptions);
  

    const studyObj = {
      question: allOptions[0],
      options: _.shuffle([allOptions[0].meaning, allOptions[1].meaning, allOptions[2].meaning, allOptions[3].meaning])
    };

    return studyObj;
  }


function vocabularyReducer(vocabulary = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RETRIEVE_VOCABULARY:
        return payload;
    case RETRIEVE_VOCABULARY_BY_LEVEL:
      return Object.assign({}, vocabulary, {
        all: {list: payload.data, jlpt:payload.jlpt}
      })
    case RETRIEVE_STUDY_VOCABULARY:
      return Object.assign({}, vocabulary, {
        study: formatStudyVocabulary(payload)
      })
    case SET_STUDY_VOCABULARY_OPTIONS_RESULT:
      const studyObj = Object.assign({}, vocabulary.study, { result: payload })

      return Object.assign({}, vocabulary, {
        study: studyObj
      })
    case SET_STUDIED_VOCABULARY: 
      const questionObj = Object.assign({}, vocabulary.study.question, { studied: payload })
      const studiedObj = Object.assign({}, vocabulary.study, { question: questionObj})

      return Object.assign({}, vocabulary, {
        study: studiedObj
      })
    case LIKE_VOCABULARY: 
      var vocab_list = vocabulary.all.list;

      //refactor this
      vocab_list.forEach((element, index) => {
        if(element.id === payload.likeable_id) {
          vocab_list[index].liked = true;
        }
      });

      const vocab_all_obj = Object.assign({}, vocabulary.all, { list: [...vocab_list] })


      return Object.assign({}, vocabulary, {
        all: vocab_all_obj
      })
    case UNLIKE_VOCABULARY: 
      var vocab_list = vocabulary.all.list;

      //refactor this
      vocab_list.forEach((element, index) => {
        if(element.id === payload.likeable_id) {
          vocab_list[index].liked = false;
        }
      });

      var new_vocab_list = Object.assign({}, vocabulary.all, { list: [...vocab_list] })


      return Object.assign({}, vocabulary, {
        all: new_vocab_list
      })
    case GET_VOCABULARY_PROGRESS:
      var progress_obj = Object.assign({}, vocabulary.progress, FORMAT_PROGRESS(payload))

      return Object.assign({}, vocabulary, {
        progress: progress_obj
      })
    case VOCABULARY_LEVEL_CHANGE_SHOWED:
      var progress_obj = Object.assign({}, vocabulary.progress, { showNewLevelCard: payload })

      return Object.assign({}, vocabulary, {
        progress: progress_obj
      })
    case VOCABULARY_JUST_LEARNED:
      var progress_obj = Object.assign({}, vocabulary.progress, { vocabularyJustLearned: payload })

      return Object.assign({}, vocabulary, {
        progress: progress_obj
      })
    default:
      return vocabulary;
    }
  };

  export default vocabularyReducer;