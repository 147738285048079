import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { signUp } from "../../actions/auth";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Register(props) {

    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
          .required('Full Name is required'),
        email: Yup.string()
          .required('Email is required')
          .email('Email is invalid'),
        password: Yup.string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters')
          .max(40, 'Password must not exceed 40 characters'),
        confirmPassword: Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
        acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required')
      });

    const {
        register,
        handleSubmit,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(validationSchema)
      });

    const onSubmit = data => {
        dispatch(signUp(data.email, data.password))
        .then(response => {
          console.log("response to component");
          console.log(response);
          
        navigate('/');

        })
        .catch(e => {
        console.log(e);
        });
      };

    const initialUserState = {
        id: null,
        email: "",
        password: "",
        active: false
      };
      
    const [currentUser, setCurrentUser] = useState(initialUserState);
    // const [message, setMessage] = useState("");
    const dispatch = useDispatch();
    
    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentUser({ ...currentUser, [name]: value });
      };
      
    return (

      <main className="page-wrapper d-flex flex-column">
      

        <section className="container d-flex justify-content-center align-items-center" style={{flex: "1 0 auto"}}>
            <div className="signin-form mt-3">
              <div className="signin-form-inner">
                
                <div>
                  <h1 className="h2 text-center">Sign up</h1>
                  <p className="fs-ms text-muted mb-4 text-center">Registration takes less than a minute but gives you full control over your orders.</p>
                  <form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">

                    <input
                     type="fullName"
                     id="fullName"
                     name="fullName"
                     placeholder="Full Name"
                     aria-describedby="nameHelp"
                     autoComplete="off"
                     onChange={handleInputChange}
                     {...register('fullName')}
                     className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}/>
                     <div className="invalid-feedback">{errors.fullName?.message}</div>

                    </div>
                    <div className="mb-3">
                      
                      <input
                        id="email"
                        name="email"
                        placeholder="Email"
                        aria-describedby="nameHelp"
                        autoComplete="off"
                        onChange={handleInputChange}
                        {...register('email')}
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}/>
                      <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                    <div className="input-group mb-3">
                      <div className="password-toggle w-100">

                        <input
                          type="password"
                          id="password"
                          name="password"
                          autoComplete="off"
                          onChange={handleInputChange}
                          placeholder="Password"
                          {...register('password')}
                          className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                          <div className="invalid-feedback">{errors.password?.message}</div>

                        {/* <label className="password-toggle-btn" aria-label="Show/hide password">
                          <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator"></span>
                        </label> */}
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <div className="password-toggle w-100">

                        <input
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm password"
                          autoComplete="off"
                          {...register('confirmPassword')}
                          className={`form-control ${
                          errors.confirmPassword ? 'is-invalid' : '' }`} />

                          <div className="invalid-feedback">{errors.confirmPassword?.message}</div>

                        {/* <label className="password-toggle-btn" aria-label="Show/hide password">
                          <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator"></span>
                        </label> */}
                      </div>
                    </div>
                    <button className="btn btn-primary d-block w-100" type="submit">Sign up</button>
                    <p className="fs-sm pt-3 mb-0 text-center">Already have an account? 
                      <Link className='fw-medium' to={"/login"}> Sign in </Link>
                    </p>
                  </form>
                </div>
                <div className="border-top text-center mt-4 pt-4">
                  <p className="fs-sm fw-medium text-heading">Or sign in with</p><a className="btn-social bs-facebook bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-facebook"></i></a><a className="btn-social bs-twitter bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-twitter"></i></a><a className="btn-social bs-instagram bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-instagram"></i></a><a className="btn-social bs-google bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-google"></i></a>
                </div>
              </div>
            </div>
          </section>
        </main>
    );
  }
  export default Register;