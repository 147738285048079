import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from '../Loading';
import MultipleOptionCard from "../base/MultipleOptionCard"
import NewStudyItemCard from "../base/NewStudyItemCard"
import ItemLearnedCard from "../base/ItemLearnedCard";
import NewLevelBanner from "../base/NewLevelBanner";

import {
    retrieveVocabularyStudy,
    setVocabularyStudyOptionsResult,
    setStudiedVocabulary,
    getVocabularyProgress,
    setVocabularyProgressShowed,
    setVocabularyJustLearned
} from "../../actions/vocabulary";

import{
    ITEM_PERCENTAGE_PASS,
    ITEM_COUNT_PASS
    } from "../../globalConstants";

const VocabularyMultipleOption = () => {

    const result = null;
  
    const dispatch = useDispatch();
    const vocabularyProgress = useSelector(state => state.vocabulary.progress);
    const showNewLevelCard = useSelector(state => state.vocabulary.progress.showNewLevelCard);
    const studyVocabulary = useSelector(state => state.vocabulary.study);
    const question = studyVocabulary.question;

    useEffect(() => {
        dispatch(retrieveVocabularyStudy(vocabularyProgress.level));
    }, []);

    useEffect(() => {

        if(question != undefined){
        
            let newTotal = question.total + 1;
            let newRate = (question.correct + 1) / newTotal;

            let isJustLearned = ((newRate >= ITEM_PERCENTAGE_PASS) && (newTotal >= ITEM_COUNT_PASS));
                
            if (isJustLearned){
                dispatch(setVocabularyJustLearned(true));
            }
        }
    
            // return isJustLearned
    
      }, [studyVocabulary.result]);


    const nextStudyVocabulary = () => {
        dispatch(retrieveVocabularyStudy(vocabularyProgress.level))
                .catch(e => {
                console.log(e);
        });
    };

    const newLevelStudy = () => {
        dispatch(retrieveVocabularyStudy(vocabularyProgress.level))
                .catch(e => {
                console.log(e);
        });

        dispatch(setVocabularyProgressShowed())
                .catch(e => {
                console.log(e);
        });
    };

    const nextStudyVocabularyLearned = () => {
        dispatch(setVocabularyJustLearned(false));
        dispatch(getVocabularyProgress());
      };

    const setVocabularyStudied = (answer) => {
        dispatch(setStudiedVocabulary())
          .catch(e => {
            console.log(e);
          });
      };

    const setVocabularyStudyResult = (answer) => {

        const studyHistoryRecord = formatStudyHistoryRecord(studyVocabulary.question, answer);

        dispatch(setVocabularyStudyOptionsResult(studyHistoryRecord))
        .catch(e => {
            console.log(e);
        });
    };

    function formatStudyHistoryRecord(question, answer){
        const record = {
          vocabulary_id: question.id,
          vocabulary: question.kanji,
          result: question.meaning === answer, 
          answer: answer, 
          correct_answer: question.meaning,
          quiz_type: "options"
        };
    
        return record;
      }

    if(studyVocabulary != undefined & Object.keys(studyVocabulary).length !== 0){
        if (studyVocabulary.result && vocabularyProgress.vocabularyJustLearned)  {     
            
            return (<ItemLearnedCard 
                        kanji={question.kanji || question.hiragana}
                        next={nextStudyVocabularyLearned}
                        levelCorrect={vocabularyProgress.correct + 1}
                        levelTotal={vocabularyProgress.total}
                        level={vocabularyProgress.level}
                        />)

        } else if (showNewLevelCard)  {     
            return <NewLevelBanner 
                    level={vocabularyProgress.level}
                    next={newLevelStudy} />               

        } else if(question.correct === 0 & !question.studied){
            return <NewStudyItemCard
                key={question.id}
                kanji={question.kanji}
                pronunciation1={question.hiragana}
                meaning={question.meaning}
                setStudied={setVocabularyStudied} />
        
        } else {
            return (<MultipleOptionCard 
                options={studyVocabulary.options}
                result={studyVocabulary.result}
                kanji={question.kanji}
                hiragana={question.hiragana}
                pct={question.pct}
                meaning={question.meaning}
                total={question.total}
                correct={question.correct}
                jlpt={question.jlpt}
                level={vocabularyProgress.level}
                modelName={"Vocabulary"}
                next={nextStudyVocabulary}
                setResult={setVocabularyStudyResult}/>)
        }
    } else {
        return (<Loading />)
    }
  
};
export default VocabularyMultipleOption;