import React from "react";
import { Link } from 'react-router-dom';

const DashboardCard = props => {
  let backgroundClass = props.isDefault ? "bg-primary" : "";
  let textClass = props.isDefault ? "text-light" : "";
  let buttonClass = props.isDefault ? "btn-light" : "btn-primary";

  return (
    
    <div>
      <div className="col" >
        <div className={"card h-100 py-lg-4 " + backgroundClass}>
          <div className="card-body w-100 mx-auto text-center" >
            <h3 className={textClass}>{props.topText}</h3>
            <div className={"display-2 text-primary " + textClass}>{props.title}</div>
            <div className={"mb-4 " + textClass}>{props.subText}</div>
            {/* <p className={"mb-4 pb-4 " + textClass}>{props.description}</p> */}
            {(props.progress !== undefined) &&
              <div className="progress mb-3">
                <div className="progress-bar fw-semibold bg-success" role="progressbar" style={{width: props.progress + "%"}} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100">
                  {props.progress}%
                </div>
              </div>
            }
            <Link className={"btn w-100 " + buttonClass} to={props.link || "/"}>{props.btnText}</Link>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default DashboardCard;