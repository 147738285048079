import _  from "lodash";
import {
    RETRIEVE_LIKES,
    RETRIEVE_STUDY_LIKES,
    SET_LIKE_PRACTICE_ANSWER,
    SET_LIKE_PRACTICE_RESULT,
    NEXT_STUDY_LIKES
  } from "../actions/types";

import{
  CUSTOM_STUDY_TOTAL_ITEMS
} from "../globalConstants";

const initialState = {all:[], count: 0, study:{list:[], current:{}, result: null, answer:"", index: 0, score: 0}};

const getCurrentStudy = (studyList, payload) => {
  return studyList[payload];
};

function LikesReducer(likes = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RETRIEVE_LIKES:
      return Object.assign({}, likes, {
        all: payload,
        count: payload.length
      })
  case RETRIEVE_STUDY_LIKES:
      var shuffledList = _.shuffle(payload);
      shuffledList = shuffledList.length > CUSTOM_STUDY_TOTAL_ITEMS ? _.take(shuffledList, CUSTOM_STUDY_TOTAL_ITEMS) : shuffledList;
      var studyObj = Object.assign({}, likes.study, { list: shuffledList, index: 0, current: shuffledList[0], result: null, answer:"", score: 0 })

      return Object.assign({}, likes, {
        study: studyObj
      })
  case NEXT_STUDY_LIKES:
    var studyObj = Object.assign({}, likes.study, { index: payload, 
                                                    current: getCurrentStudy(likes.study.list, payload), 
                                                    result: null, 
                                                    answer:""})

    return Object.assign({}, likes, {
      study: studyObj
    })
  case SET_LIKE_PRACTICE_ANSWER:
    const answerObj = Object.assign({}, likes.study, { answer: payload })

    return Object.assign({}, likes, {
      study: answerObj
    })
  case SET_LIKE_PRACTICE_RESULT:
    var score = payload ? likes.study.score + 1 : likes.study.score;
    const resultObj = Object.assign({}, likes.study, { result: payload, score: score })

    return Object.assign({}, likes, {
      study: resultObj
    })
    default:
      return likes;
  }
};

export default LikesReducer;