import {
    LOGIN,
    LOGINERROR,
    SIGNUP,
    LOGOUT,
  } from "./types";

import AuthDataService from "../services/AuthService";

export const login = (email, password) => async (dispatch) => {
    try {
      const res = await AuthDataService.login(email, password); 
      res.data.email = email;

      dispatch({
        type: LOGIN,
        payload: res.data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      // console.log(err);
      dispatch({
        type: LOGINERROR,
        payload: "Something went wrong, review your information and try again.",
      });
      return Promise.reject(err);
    }
  };

export const signUp = (email, password) => async (dispatch) => {

  try {
    const res = await AuthDataService.signUp(email, password);

    dispatch({
      type: SIGNUP,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const logout = () => async (dispatch) => {
  try {
    const res = await AuthDataService.logout(); 

    dispatch({
      type: LOGOUT,
      payload: {},
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
  
};

// export const loginError = (error) => async (dispatch) => {
//   try {
//     dispatch({
//       type: LOGINERROR,
//       payload: error
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

export const loginError = (value) => {
  return {
    type: 'LOGINERROR',
    payload: value
  }
}

