import {
    RETRIEVE_PRACTICE_VERB,
    SET_PRACTICE_VERB_RESULT,
    SET_PRACTICE_VERB_RESPONSE,
    RETRIEVE_VERB_BY_LEVEL,
    SET_STUDIED_VERB,
    LIKE_VERB,
    UNLIKE_VERB,
    GET_VERB_PROGRESS
  } from "./types";
  import VerbDataService from "../services/VerbService";
  import LikeDataService from "../services/LikeService";

  export const retrieveVerbByLevel = (level) => async (dispatch) => {
    try {
      const res = await VerbDataService.getByLevel(level);
      
      dispatch({
        type: RETRIEVE_VERB_BY_LEVEL,
        payload: {data: res.data, jlpt: level},
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const retrieveStudyVerb = () => async (dispatch) => {
    try {
      const res = await VerbDataService.getStudyVerb();
      
      dispatch({
        type: RETRIEVE_PRACTICE_VERB,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const setStudyResult = (studyRecord) => async (dispatch) => {
    try {
      const res = await VerbDataService.postStudyVerbHistory(studyRecord);
      
      dispatch({
        type: SET_PRACTICE_VERB_RESULT,
        payload: studyRecord.result,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const setStudyAnswer = (answer) => async (dispatch) => {
    try {
      dispatch({
        type: SET_PRACTICE_VERB_RESPONSE,
        payload: answer,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const setStudiedVerb = () => async (dispatch) => {
    try {
      dispatch({
        type: SET_STUDIED_VERB,
        payload: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const likeVerb = (params) => async (dispatch) => {
    try {
      const res = await LikeDataService.like(params);
      
      dispatch({
        payload: res.data,
        type: LIKE_VERB,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const unlikeVerb = (params) => async (dispatch) => {
    try {
      const res = await LikeDataService.unlike(params);
      
      dispatch({
        payload: res.data,
        type: UNLIKE_VERB,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const getVerbProgress = () => async (dispatch) => {
    try {
      const res = await VerbDataService.getProgress();
      
      dispatch({
        payload: res.data,
        type: GET_VERB_PROGRESS,
      });
    } catch (err) {
      console.log(err.response);
    }
  };
  