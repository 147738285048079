import {
    RETRIEVE_KANJI, 
    RETRIEVE_KANJI_BY_LEVEL,
    RETRIEVE_STUDY_KANJI,
    SET_STUDIED_KANJI,
    SET_STUDY_KANJI_OPTIONS_RESULT,
    LIKE_KANJI,
    UNLIKE_KANJI,
    GET_KANJI_PROGRESS,
    KANJI_LEVEL_CHANGE_SHOWED,
    KANJI_JUST_LEARNED
  } from "./types";
  import KanjiDataService from "../services/KanjiService";
  import LikeDataService from "../services/LikeService";

  export const retrieveKanji = () => async (dispatch) => {
    try {
      const res = await KanjiDataService.getAll();
      dispatch({
        type: RETRIEVE_KANJI,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const retrieveKanjiByLevel = (level) => async (dispatch) => {
    try {
      const res = await KanjiDataService.getByLevel(level);
      
      dispatch({
        type: RETRIEVE_KANJI_BY_LEVEL,
        payload: {data: res.data, jlpt: level},
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const retrieveKanjiStudy = (level) => async (dispatch) => {
    try {
      const res = await KanjiDataService.getStudy(level);

      dispatch({
        type: RETRIEVE_STUDY_KANJI,
        payload: res.data,
      });
    } catch (err) {
      console.log("error found");
      console.log(err);
    }
  };

  export const setKanjiStudyOptionsResult = (studyRecord) => async (dispatch) => {
    try {
      const res = await KanjiDataService.postStudyOptionsHistory(studyRecord);

      dispatch({
        type: SET_STUDY_KANJI_OPTIONS_RESULT,
        payload: studyRecord.result,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const setStudiedKanji = () => async (dispatch) => {
    try {
      dispatch({
        type: SET_STUDIED_KANJI,
        payload: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  
  export const likeKanji = (params) => async (dispatch) => {
    try {
      const res = await LikeDataService.like(params);
      
      dispatch({
        payload: res.data,
        type: LIKE_KANJI,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const unlikeKanji = (params) => async (dispatch) => {
    try {
      const res = await LikeDataService.unlike(params);
      
      dispatch({
        payload: res.data,
        type: UNLIKE_KANJI,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const getKanjiProgress = () => async (dispatch) => {
    try {
      const res = await KanjiDataService.getProgress();
      
      dispatch({
        payload: res.data,
        type: GET_KANJI_PROGRESS,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const setKanjiProgressShowed = () => async (dispatch) => {
    try {
      
      dispatch({
        payload: false,
        type: KANJI_LEVEL_CHANGE_SHOWED,
      });

    } catch (err) {
      console.log(err.response);
    }
  };

  export const setKanjiJustLearned = (isJustLearned) => async (dispatch) => {
    try {
      
      dispatch({
        payload: isJustLearned,
        type: KANJI_JUST_LEARNED,
      });

    } catch (err) {
      console.log(err.response);
    }
  };
  