import React from 'react';
import * as wanakana from 'wanakana';

class HiraganaInput extends React.Component {
    constructor(props) {
      super(props);

      this.inputRef = React.createRef();
    }

    componentDidMount() {
      this.inputRef.focus();
      wanakana.bind(this.inputRef);
    }

    componentWillUnmount() {
        if(this.inputRef.current !== null){
            wanakana.unbind(this.inputRef);
        }
    }

    

    render() {

      return (
        <div>
            <input type="text" value={this.props.answer} onKeyUp={this.props.handleAnswer}  ref={ref => { this.inputRef = ref; }} className="form-control form-control-lg" placeholder="Answer(hiragana)" />
        </div>
      );
    }
  }

  export default HiraganaInput;