export const VERB_FORMS = ['formal','formal_negative','informal','informal_negative','formal_past','formal_past_negative','informal_past','informal_past_negative','te_form'];
export const VOCABULARY_LIKEABLE_TYPE = "Vocabulary";
export const KANJI_LIKEABLE_TYPE = "Kanji";
export const VERB_LIKEABLE_TYPE = "Verb";
export const CUSTOM_STUDY_TOTAL_ITEMS = 10;
export const ITEM_PERCENTAGE_PASS = .9;
export const ITEM_COUNT_PASS = 10;

export const FORMAT_PROGRESS = data => {
    // console.log(data);
    let level = 1, percentage = 0, correct = 0, total= 0;
    
    data.forEach((element, index) => {
      
      if(index == 0){
        level = element.level;
        percentage = element.level_progress;
        correct = element.level_correct;
        total = element.level_total;
        return;
      }

      if(percentage == 100){
        level = element.level;
        percentage = element.level_progress;
        correct = element.level_correct;
        total = element.level_total;
      }
     
    });

    return {
        level: parseInt(level), 
        percentage: parseInt(percentage), 
        correct: parseInt(correct),
        total: parseInt(total),
        showNewLevelCard: (parseInt(percentage) == 0)
      }
  }

export const DEFAULT_VOCABULARY_OPTIONS = () => {
  return [
        {
          kanji:"後",
          meaning:"Afterwards",
          jlpt:"5",
          hiragana:"あと"
        },
        {
          kanji:"頭",
          meaning:"Head",
          jlpt:"5",
          hiragana:"あたま"
        },
        {
          kanji:"明るい",
          meaning:"Bright",
          jlpt:"5",
          hiragana:"あかるい"
        }]
}

export const DEFAULT_KANJI_OPTIONS = () => {
  return [
        {
          kanji:"大",
          meaning:"Large, Big",
          jlpt:"5",
          hiragana:"ダイ"
        },
        {
          kanji:"国",
          meaning:"Country",
          jlpt:"5",
          hiragana:"コク"
        },
        {
          kanji:"四",
          meaning:"Four",
          jlpt:"5",
          hiragana:"シ"
        }]
}