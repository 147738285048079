import { 
    SET_SHOW_HIRANAGANA,
    SET_JLPT_LEVEL,
  } from "../actions/types";
  const initialState = {showHiragana:true, jlpt:5};

function settingsReducer(settings = initialState, action) {
    const { type, payload } = action;
    switch (type) {
    case SET_SHOW_HIRANAGANA:
        return Object.assign({}, settings, {
          showHiragana: payload
        })
    case SET_JLPT_LEVEL:
        return Object.assign({}, settings, {
          jlpt: payload
        })
      default:
        return settings;
    }
  };

  export default settingsReducer;