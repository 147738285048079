import { connect, useSelector } from 'react-redux';
import PublicDashboard from './PublicDashboard';
import LoggedDashboard from './LoggedDashboard';

function Dashboard() {

  const currentUser = useSelector(state => state.auth);
  const isSessionEmpty = Object.keys(currentUser).length === 0;

    return (
    <div>
      { !isSessionEmpty      
        ? <LoggedDashboard />
        : <PublicDashboard />
      }
      
    </div>
    );
  }

export default Dashboard;