import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemProgressBox from "../base/ItemProgressBox";
import ScrollToTop from "react-scroll-up";

import {
  retrieveKanjiByLevel,
  likeKanji,
  unlikeKanji
} from "../../actions/kanji";

import{
  KANJI_LIKEABLE_TYPE
} from "../../globalConstants";

const KanjiList = () => {
  const kanjiList = useSelector(state => state.kanji.all.list);
  const jlptLevel = useSelector(state => state.kanji.all.jlpt);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(retrieveKanjiByLevel());
  }, []);

  // const handleJlptChange = (event) => {
  //   dispatch(retrieveKanjiByLevel(event.target.value))
  //     .catch(e => {
  //       console.log(e);
  //     });
  // };

  const toggleKanjiLike = (event, id, liked) => {
    event.stopPropagation();

    var like_params = {
      like: {
        likeable_id: id, 
        likeable_type: KANJI_LIKEABLE_TYPE
      }
    };
    
    if(liked){
      dispatch(unlikeKanji(like_params))
        .catch(e => {
        console.log(e);
      });
    } else {
      dispatch(likeKanji(like_params))
        .catch(e => {
        console.log(e);
      });
    }

  };

  return (
    <div>
        <h1>Kanji List</h1>
        
        <div className="container">
          {/* <div className="row">
            <div className="col-2 col-sm-4 col-xl-2">
              <select className="form-select form-select-sm" aria-label=".form-select-sm" value={jlptLevel} onChange={handleJlptChange}>
                  <option value="4">JLPT 4</option>
                  <option value="5">JLPT 5</option>
              </select>
            </div>
          </div> */}
          <br/>
          <div className="row">
            {kanjiList && kanjiList.map((kanji, index) => (
                <ItemProgressBox 
                key={kanji.id}
                id={kanji.id}
                pct={kanji.pct}
                correct={kanji.correct}
                total={kanji.total}
                kanji={kanji.kanji}
                pronunciation1={kanji.onyomi}
                pronunciation2={kanji.kunyomi}
                meaning={kanji.meaning}
                likeItem={toggleKanjiLike}
                liked={kanji.liked}
                level={kanji.level}                
                />
            ))}
          </div>
        </div>
        
        <ScrollToTop showUnder={160} style={{zIndex:'20'}}>
          <button type="button" className="btn btn-dark btn-icon">
            <i className="ai-arrow-up"></i>
          </button>
        </ScrollToTop>

    </div>
  );
  
};
export default KanjiList;