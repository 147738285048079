import React, { useEffect } from "react";

const StudyResultCard = props => {
    let tryItButton = null;
    
    useEffect(()=>{
        tryItButton.focus();
    })

    return (
        <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-12">
                        <div className={"card bg-primary"}>
                            <div className="card-body">
                                <h3 className="text-center text-white">Study Results</h3>
                                <p className={"text-center fs-4 text-white"}>You got <b>{props.correctItems}</b> correct and  <b>{props.totalItems - props.correctItems}</b> wrong items.</p>
                                <h1 className={"display-1 text-center text-white card-title"}>{props.correctItems}/{props.totalItems}</h1>

                            </div>
                            <div className="card-footer fs-sm text-muted">
                                <button type="button" className="btn btn-light w-100" onClick={ () => props.studyAgain() } ref={(button) => { tryItButton = button; }}>
                                    <i className="ai-emoji-smile me-2"></i>
                                    Continue studying!
                                </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        );
  
};

export default StudyResultCard;