import React from "react";
import { Link } from "react-router-dom";

const KanjiMenu = props => {

    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Kanji</a>
            <ul className="dropdown-menu">
                { !isEmpty(props.loggedUser) &&
                    <li><Link to={"/kanji"} className="dropdown-item">List</Link></li>
                }
                <li><Link to={"/kanji/options"} className="dropdown-item">Options Quiz</Link></li>
            </ul>
        </li>);
  
};

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}


export default KanjiMenu;