import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemProgressBox from "../base/ItemProgressBox";
import ScrollToTop from "react-scroll-up";

import {
    retrieveVocabularyByLevel,
    likeVocabulary,
    unlikeVocabulary
} from "../../actions/vocabulary";

import{
  VOCABULARY_LIKEABLE_TYPE
} from "../../globalConstants";

const VocabularyList = () => {
  const vocabularyList = useSelector(state => state.vocabulary.all.list);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(retrieveVocabularyByLevel());
  }, []);

  const handleJlptChange = (event) => {
      dispatch(retrieveVocabularyByLevel(event.target.value))
        .catch(e => {
          console.log(e);
        });
    };

  const toggleVocabularyLike = (event, vocab_id, liked) => {
    event.stopPropagation();

    var like_params = {like: {likeable_id: vocab_id, likeable_type: VOCABULARY_LIKEABLE_TYPE}};
    
    if(liked){
      dispatch(unlikeVocabulary(like_params))
        .catch(e => {
        console.log(e);
      });
    } else {
      dispatch(likeVocabulary(like_params))
        .catch(e => {
        console.log(e);
      });
    }

    };

  return (
    
    <div>
        <h1>Vocabulary List</h1>
        
        <div className="container">
          {/* <div className="row">
            <div className="col-3">
              <select className="form-select form-select-sm" aria-label=".form-select-sm" value={jlptLevel} onChange={handleJlptChange}>
                  <option value="4">JLPT 4</option>
                  <option value="5">JLPT 5</option>
              </select>
            </div>
          </div> */}
          <br/>
          <div className="row">
            {vocabularyList && vocabularyList.map((vocabulary, index) => (
                <ItemProgressBox 
                key={vocabulary.id}
                id={vocabulary.id}
                pct={vocabulary.pct}
                correct={vocabulary.correct}
                total={vocabulary.total}
                kanji={vocabulary.kanji}
                pronunciation1={vocabulary.hiragana}
                pronunciation2=""
                meaning={vocabulary.meaning}
                likeItem={toggleVocabularyLike}
                liked={vocabulary.liked}
                level={vocabulary.level}
                />
            ))}
          </div>
        </div>
        <ScrollToTop showUnder={160} style={{zIndex:'20'}}>
          <button type="button" className="btn btn-dark btn-icon">
            <i className="ai-arrow-up"></i>
          </button>
        </ScrollToTop>
    </div>
  );
  
};
export default VocabularyList;