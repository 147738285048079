import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { login, loginError } from "../../actions/auth";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function Login(props) {
    const navigate = useNavigate();
    const loginError = useSelector(state => state.auth.error);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .required('Email is required')
          .email('Email is invalid'),
        password: Yup.string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters')
          .max(40, 'Password must not exceed 40 characters'),
      });

    const {
        register,
        handleSubmit,
        formState: { errors }
      } = useForm({
        resolver: yupResolver(validationSchema)
      });

    const initialUserState = {
        id: null,
        email: "",
        password: "",
        active: false
      };
      
    const [currentUser, setCurrentUser] = useState(initialUserState);

    const dispatch = useDispatch();
    
    const handleInputChange = event => {
        const { name, value } = event.target;
        setCurrentUser({ ...currentUser, [name]: value });
      };

    const loginUser = (data) => {
    dispatch(login(data.email, data.password))
        .then(response => {
        navigate('/');
        })
        .catch(e => {
        // console.log(e.response);
        });
    };
      
    return (

        <div>

    <main className="page-wrapper d-flex flex-column">
      

    <section className="container d-flex justify-content-center align-items-center" style={{flex: "1 0 auto"}}>
        
        <div className="signin-form mt-3">
        
          <div className="signin-form-inner">

          { loginError &&
            <div className="alert alert-danger" role="alert">
              {loginError}
            </div>
          } 
            
            <div className="view show" id="signin-view">
              <h1 className="h2 text-center">Sign in</h1>
              <p className="fs-ms text-muted mb-4 text-center">Sign in to your account using email and password provided during registration.</p>
              <form className="needs-validation" onSubmit={handleSubmit(loginUser)}>

                <div className="input-group mb-3">
                  {/* <i className="ai-mail position-absolute top-50 start-0 translate-middle-y ms-3"></i> */}

                  <input
                     type="email"
                     id="email"
                     autoComplete="off"
                     name="email"
                     placeholder="Email"
                     aria-describedby="emailHelp"
                     onChange={handleInputChange}
                     {...register('email')}
                     className={`form-control rounded ${errors.email ? 'is-invalid' : ''}`} />

                  <div className="invalid-feedback">{errors.email?.message}</div>
                </div>

                <div className="input-group mb-3">
                  {/* <i className="ai-lock position-absolute top-50 start-0 translate-middle-y ms-3"></i> */}
                  <div className="password-toggle w-100">
                    <input
                       type="password"
                       id="password"
                       name="password"
                       placeholder="Password"
                       autoComplete="off"
                       onChange={handleInputChange}
                       {...register('password')}
                       className={`form-control ${errors.password ? 'is-invalid' : ''}`} />

                    {/* <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input className="password-toggle-check" type="checkbox" /><span className="password-toggle-indicator"></span>
                    </label> */}

                    <div className="invalid-feedback">{errors.password?.message}</div>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-3 pb-1">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="keep-signed-2" />
                    <label className="form-check-label" htmlFor="keep-signed-2">Keep me signed in</label>
                  </div>
                  <Link className='nav-link-style fs-ms' to={"/forgot-password"}> Forgot password? </Link>
                </div>
                <button className="btn btn-primary d-block w-100" type="submit">Sign in</button>
                <p className="fs-sm pt-3 mb-0 text-center">Don't have an account? 
                  <Link className='fw-medium' to={"/register"}> Sign up </Link>
                </p>
              </form>
            </div>
            <div className="border-top text-center mt-4 pt-4">
              <p className="fs-sm fw-medium text-heading">Or sign in with</p>
              <a className="btn-social bs-facebook bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-facebook"></i></a>
              <a className="btn-social bs-twitter bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-twitter"></i></a>
              <a className="btn-social bs-instagram bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-instagram"></i></a>
              <a className="btn-social bs-google bs-outline bs-lg mx-1 mb-2" href="#"><i className="ai-google"></i></a>
            </div>
          </div>
        </div>
      </section>
    </main>
    
  </div>

        // <div className="edit-form">
        //     <form onSubmit={handleSubmit(loginUser)}>
        //         <div className="mb-3">
        //         <label htmlFor="email" className="form-label">Email</label>
        //         <input
        //             type="email"
        //             id="email"
        //             name="email"
        //             aria-describedby="emailHelp"
        //             onChange={handleInputChange}
        //             {...register('email')}
        //             className={`form-control ${errors.email ? 'is-invalid' : ''}`}
        //         />
        //         <div className="invalid-feedback">{errors.email?.message}</div>
        //         </div>

        //         <div className="mb-3">
        //           <label htmlFor="password" className="form-label">Password</label>
        //           <input
        //               type="password"
        //               id="password"
        //               name="password"
        //               onChange={handleInputChange}
        //               {...register('password')}
        //               className={`form-control ${errors.password ? 'is-invalid' : ''}`}
        //           />
        //           <div className="invalid-feedback">{errors.password?.message}</div>
        //         </div>

        //         <div className="form-group">
        //             <button type="submit" className="btn btn-primary">
        //               Login
        //             </button>
        //         </div>
        //     </form>
        //     <div className="mb-3">
        //       <p>
        //         Don't have an account yet?
        //         <Link to={"/register"}> Sign Up</Link>
        //       </p>
        //     </div>
        //   </div>
    );
  }
  export default Login;