import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setStudyResult, setStudyAnswer, retrieveStudyVerb } from "../../actions/verbs";
import HiraganaInput from './HiraganaInput';
import StudyResultText from '../base/StudyResultText';

function VerbQuestion(props) {

  const dispatch = useDispatch();

  const verb = props.verb;
  const { result, form, answer } = useSelector(state => state.verb.study);
  const nextButtonRef = useRef();
  const answerRef = useRef();
  
  function formatForm(){
    return form.replaceAll("_", " ");
  }

  useEffect(() => {

    if(result == null){

    }
    else{
      nextButtonRef.current.focus();
    }
  }, [result]);

  function formatStudyHistoryRecord(){
    const record = {
      verb: verb.hiragana, 
      form: form, 
      result: verb[form] == answer, 
      answer: answer, 
      correct_answer: verb[form],
      quiz_type: "typing",
      verb_id: verb.id
    };

    return record;
  }
  
  const handleAnswer = event => {
    const { name, value } = event.target;

    if ((event.key === 'Enter' || event.keyCode === 13) && value != "") {
      checkAnswer();
    } else {

      
      dispatch(setStudyAnswer(value))
      .catch(e => {
        console.log(e);
      });
    }

  };

  const checkAnswer = () => {
    const studyHistoryRecord = formatStudyHistoryRecord();

    dispatch(setStudyResult(studyHistoryRecord))
      .catch(e => {
        console.log(e);
      });
  };

  const nextStudyVerb = (event) => {
      dispatch(retrieveStudyVerb())
        .catch(e => {
          console.log(e);
        });
    };

  
  let rating = parseInt(verb[form + "_pct"]);

  return (
    <div>
    
    <div className="container">
      <div className="row">
        <div className="col col-lg-6 offset-lg-3">
          <div className="card">
            <h5 className="card-header">
                What is the &nbsp;
                <strong className='text-capitalize'>{formatForm()}</strong> 
                &nbsp;
                form
                of:
            </h5>
            <div className="card-body">
                <h1 className="display-4">{verb.kanji}</h1>
                <p className="card-title h5">{verb.hiragana}</p>
                <p className="card-text text-muted">{verb.meaning}</p>
                <div className="container">
                  
                {result == null &&
                  <div>
                    <div className="row top-buffer">
                    <div className="col">
                        <HiraganaInput handleAnswer={handleAnswer} answerRef={answerRef} />
                    </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <br/>
                        <button
                          type="submit"
                          className="btn btn-outline-primary float-end"
                          onClick={checkAnswer}
                        >Check Answer</button>
                      </div>
                    </div>
                  </div>
                }

                {result != null &&
                  <div>
                    <StudyResultText result={result} kanji={verb.kanji} correctAnswer={verb[form]} meaning={formatForm()} />
                    
                    <button
                      type="submit"
                      className="btn btn-outline-primary float-end"
                      onClick={nextStudyVerb}
                      ref={nextButtonRef}
                    >Next Question
                    </button>
                  </div>
                }
              </div>
            </div>
            <div className="card-footer fs-sm text-center">

              <div className="text-muted">{rating}% Rating</div>
          </div>
          </div>
        </div>
      </div>
    </div>
</div>
  );
}

export default VerbQuestion;
