import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.js";
import "./scss/theme.scss";
import Register from "./components/auth/Register";
import User from "./components/user/User";
import Login from "./components/auth/Login";
import Dashboard from './components/dashboard/Dashboard';
import ProfileMenu from './components/ProfileMenu';
import KanjiMenu from './components/KanjiMenu';
import VerbMenu from './components/VerbMenu';
import VocabularyMenu from './components/VocabularyMenu'
import KanjiList from './components/kanji/KanjiList';
import VocabularyList from './components/vocabulary/VocabularyList';
import VerbStudyProgressList from './components/verb/VerbStudyProgressList';
import VerbStudy from './components/verb/VerbStudy';
import KanjiMultipleOption from './components/kanji/KanjiMultipleOption';
import VocabularyMultipleOption from './components/vocabulary/VocabularyMultpleOption';
import avatarImage from './assets/images/avatars/ONI4.png';
import Footer from './components/Footer';
import ForgotPassword from './components/auth/ForgotPassword';
import NotFound from './components/NotFound';
import LikedStudy from './components/like/LikedStudy';

function AppComponent(props) {

  const currentUser = useSelector(state => state.auth.user);
  // const isSessionEmpty = Object.keys(currentUser).length === 0;
  // const isSessionEmpty = currentUser;

  return (
    <Router>
      <main className="page-wrapper">
      
      <header className="header navbar navbar-expand-lg navbar-light bg-light navbar-shadow navbar-sticky" data-scroll-header data-fixed-element>
        <div className="container px-0 px-xl-3">
          <Link className="navbar-brand flex-shrink-0 pe-lg-2 me-lg-4" to={"/"}>
            妖怪 Japanese
          </Link>
          <button className="navbar-toggler me-n2 me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu"><span className="navbar-toggler-icon"></span></button>
          
          
            <div className="offcanvas offcanvas-collapse offcanvas-end" id="primaryMenu">
              <div className="offcanvas-header navbar-shadow">
                <h5 className="mt-1 mb-0">Menu</h5>
                <button className="btn-close lead" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav">
                  <VerbMenu loggedUser={currentUser || {}} />
                  <VocabularyMenu loggedUser={currentUser || {}} />
                  <KanjiMenu loggedUser={currentUser || {}} />
                  <li className='nav-item'>
                    <Link className="nav-link" to={"/like/study"}>Bookmarks</Link>
                  </li>
                </ul>
              </div>
            </div>
          
          <ProfileMenu loggedUser={currentUser || {}} avatarImage={avatarImage} />
        </div>
      </header>
      
      <div className="container pt-5 pb-4">
      <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route exact path="/register" element={<Register />} />
          <Route path="/user/" element={<User />} />
          <Route path="/login" element={<Login />} />
          <Route path="/kanji/" element={<KanjiList />} />
          <Route path="/kanji/options" element={<KanjiMultipleOption />} />
          <Route path="/vocabulary/" element={<VocabularyList />} />
          <Route path="/vocabulary/options" element={<VocabularyMultipleOption />} />
          <Route path="/verbs" element={<VerbStudyProgressList/>} />
          <Route path="/verb/study" element={<VerbStudy />} />
          <Route path="/like/study" element={<LikedStudy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </main>
    <Footer/>
    </Router>
  );
}

const mapSatateToProps = (state) => {
  return {
    loggedUser: state.auth
  }
}

const App = connect(
  mapSatateToProps,
    {}
  )(AppComponent)

export default App;