import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VerbQuestion from './VerbQuestion';
import Loading from '../Loading';
import NewStudyItemCard from "../base/NewStudyItemCard";

import {
  retrieveStudyVerb,
  setStudiedVerb
} from "../../actions/verbs";
const VerbStudy = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveStudyVerb());
  }, []);

  const setVerbStudied = (answer) => {
    dispatch(setStudiedVerb())
      .catch(e => {
        console.log(e);
      });
  };

  const verb = useSelector(state => state.verb.study.verb);
  const form = useSelector(state => state.verb.study.form);
  
  if(verb != undefined){
    if(parseInt(verb[form + "_pct"]) === 0 & !verb.studied){

      return <NewStudyItemCard
                key={verb.id}
                kanji={verb.kanji}
                title={form.replaceAll("_", " ")}
                pronunciation2={verb[form]}
                meaning={verb.meaning}
                setStudied={setVerbStudied}
                />

    } else {
      return (<VerbQuestion verb={verb} form={form} />)
    }
  } else {
    return (<Loading />)
  }
  
};
export default VerbStudy;