import React, { useEffect } from "react";

const NewStudyItemCard = props => {
    let tryItButton = null;
    
    useEffect(()=>{
        tryItButton.focus();
    })

    return (
        <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-12">
                        <div className={"card bg-primary"}>
                            <div className="card-body">
                                <h3 className="text-center text-white">{props.title}</h3>
                                <p className={"text-center fs-4 text-white"}>{props.pronunciation1}</p>
                                <p className={"text-center fs-4 text-white"}>{props.pronunciation2}</p>
                                <h1 className={"display-1 text-center text-white card-title"}>{props.kanji === "" ? props.pronunciation1 : props.kanji}</h1>
                                <p className={"text-center fs-2 text-white"}>{props.meaning}</p>

                            </div>
                            <div className="card-footer fs-sm text-muted">
                                <button type="button" className="btn btn-light w-100" onClick={ () => props.setStudied() } ref={(button) => { tryItButton = button; }}>
                                    <i className="ai-emoji-smile me-2"></i>
                                    Let's try it!
                                </button>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        );
  
};

export default NewStudyItemCard;