import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { logout } from "./actions/auth";
import { Provider } from 'react-redux';
import store from './store';

import http from "./http-common";

const router = (
  <Provider store={store}>
  <App />
</Provider>
);

const {dispatch} = store;

http.interceptors.response.use((response) => response, (error) => {

  if (error.response.status === 401) {
    dispatch(logout())
        .then(response => {
            console.log("error 401 Intercepted -> then");
            window.location.href = '/login';
        })
        .catch(e => {
            console.log(e);
        });

  }
});


ReactDOM.render(
  router,
  document.getElementById('root')
);