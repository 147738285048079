import React, { useEffect } from "react";
import avatarImage1 from '../../assets/images/avatars/RedOni.png';

const NewLevelBanner = props => {
    let continueButton = null;
    
    useEffect(()=>{
        continueButton.focus();
    })

    return (
        <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="bg-primary rounded-3 overflow-hidden mt-2 mt-sm-4 mt-lg-5">
                            <div className="row position-relative zindex-2 align-items-center">
                                <div className="col-md-6 col-lg-5 offset-lg-1">
                                    <div className="text-center text-md-start py-5 px-4 px-sm-5 pe-md-0 ps-lg-4 ps-xl-5">
                                        <div className="d-flex align-items-center justify-content-center justify-content-md-start mb-2">
                                            <span className="bg-white opacity-70 me-2 d-none d-md-block"></span>
                                            <span className="fs-xs fw-semibold text-white opacity-70 text-uppercase">Level Up</span>
                                        </div>
                                        
                                        <h2 className="h2 text-white pb-2 pb-sm-3">Vocabulary Yokai <br/><span className="fs-4">Level {props.level}</span></h2>
                                        <button type="button" className="btn btn-outline-light fs-base" onClick={props.next} ref={(button) => { continueButton = button; }}>
                                            <i className="ai-emoji-smile me-2"></i>
                                            Continue Learning!
                                        </button>
                                        {/* <a className="btn btn-outline-light fs-base" href="#">Continue Learning</a> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <img className="d-block mx-auto mx-md-0 mt-n4 mt-md-0" src={avatarImage1} width="334" alt="Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
  
};

export default NewLevelBanner;
