import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from '../Loading';
import TextStudyCard from "../base/TextStudyCard";
import StudyResultCard from "../base/StudyResultCard";

import{
  KANJI_LIKEABLE_TYPE,
  VERB_LIKEABLE_TYPE,
  VOCABULARY_LIKEABLE_TYPE
} from "../../globalConstants";

import {
  retrieveStudyLikes,
  setStudyAnswer,
  setStudyResult,
  nextStudyLike
} from "../../actions/likes";
const LikedStudy = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveStudyLikes());
  }, []);

  const current = useSelector(state => state.likes.study.current);
  const { result, answer, index, score, list } = useSelector(state => state.likes.study);
  

  const handleAnswer = event => {
    const { name, value } = event.target;

    if ((event.key === 'Enter' || event.keyCode === 13) && value != "") {
      checkAnswer();
    } else {

      dispatch(setStudyAnswer(value))
      .catch(e => {
        console.log(e);
      });
    }

  };

  const checkAnswer = () => {
    let result = (current.liked_pronunciation == answer)

    dispatch(setStudyResult(result))
      .catch(e => {
        console.log(e);
      });
  };

  const nextLikeStudy = (event) => {
    dispatch(nextStudyLike(index + 1))
      .catch(e => {
        console.log(e);
      });
  };

  const startOver = () => {
    dispatch(retrieveStudyLikes())
      .catch(e => {
        console.log(e);
      });
  };

  const getBadgeClass = (type) => {
    if(type == VOCABULARY_LIKEABLE_TYPE){
      return "bg-success"
    } else if (type == KANJI_LIKEABLE_TYPE){
      return "bg-primary"
    } else {
      return "bg-info"
    }
  };

  
  if(list.length == 0 ){
    return (
      <div className="container">
        <div className="row">
          <div class="card border-primary col-12 col-lg-6">
              
              <div class="card-body">
                <h4 class="card-title text-primary">No bookmarks available</h4>
                <p class="card-text">To be able to practice custom bookmarked words, go ahead and bookmark Vocabulary, Verbs or Kanjis from each category list.</p>
              </div>
            </div>
            <br/>
          </div>
      </div>
    );
  } else if(index < list.length){
    if(current != undefined){

      return (
        <TextStudyCard 
                  kanji={current.liked_kanji} 
                  meaning={current.liked_meaning}
                  header="How do you say:"
                  correctAnswer={current.liked_pronunciation}
                  result={result}
                  badgeText={current.likeable_type}
                  badgeClass={getBadgeClass(current.likeable_type)}
                  handleAnswer={handleAnswer}
                  checkAnswer={checkAnswer}
                  nextStudy={nextLikeStudy} />)
          
    } else {
      return (<Loading />);
    }
  } else {
    return (<StudyResultCard
      correctItems={score}
      totalItems={index}
      studyAgain={startOver} />)
  }

}
export default LikedStudy;