import React from 'react';

function Loading() {
  return (
    <div className="jumbotron vertical-center">
      <div className="container mx-auto" style={{width: '270px'}}>
        <div className="spinner-grow" style={{width: '5rem', height: '5rem'}} role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-secondary" style={{width: '5rem', height: '5rem'}} role="status">
          <span className="sr-only"></span>
        </div>
        <div className="spinner-grow text-danger" style={{width: '5rem', height: '5rem'}} role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  );
}

export default Loading;
