import React from "react";
import { useDispatch, useSelector } from "react-redux";


import {
    setJlptLevel, setShowHiragana
} from "../actions/settings";
const Settings = () => {

  const dispatch = useDispatch();
  const showHiragana = useSelector(state => state.settings.showHiragana);
  const jlptLevel = useSelector(state => state.settings.jlpt);

  const handleJlptChange = (event) => {
    dispatch(setJlptLevel(event.target.value))
      .catch(e => {
        console.log(e);
      });
  };
  

  const handleShowHiraganaChange = (event) => {
    dispatch(setShowHiragana(event.target.checked))
      .catch(e => {
        console.log(e);
      });
  };

    return (
        <div>
            <div className="form-check form-switch float-end">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckReverse" defaultChecked={showHiragana} onChange={handleShowHiraganaChange} />
                <label className="form-check-label" htmlFor="flexSwitchCheckReverse">Show hiragana</label>
            </div>
            {/* <select className="form-select form-select-sm" aria-label=".form-select-sm" value={jlptLevel} onChange={handleJlptChange}>
                <option value="4">JLPT 4</option>
                <option value="5">JLPT 5</option>
            </select> */}
        </div>
    )
  
  
};
export default Settings;