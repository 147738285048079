import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from '../Loading';
import MultipleOptionCard from "../base/MultipleOptionCard"
import NewStudyItemCard from "../base/NewStudyItemCard"
import ItemLearnedCard from "../base/ItemLearnedCard";
import NewLevelBanner from "../base/NewLevelBanner";

import {
    retrieveKanjiStudy,
    setKanjiStudyOptionsResult,
    setStudiedKanji,
    setKanjiProgressShowed,
    getKanjiProgress,
    setKanjiJustLearned
} from "../../actions/kanji";

import{
  ITEM_PERCENTAGE_PASS,
  ITEM_COUNT_PASS
  } from "../../globalConstants";

const KanjiMultipleOption = () => {
  const studyKanji = useSelector(state => state.kanji.study);
  const question = studyKanji.question;
  const kanjiProgress = useSelector(state => state.kanji.progress);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(retrieveKanjiStudy(kanjiProgress.level));
  }, []);

  useEffect(() => {

    if(question != undefined){
      let newTotal = question.total + 1;
      let newRate = (question.correct + 1) / newTotal;

      let isJustLearned = ((newRate >= ITEM_PERCENTAGE_PASS) && (newTotal >= ITEM_COUNT_PASS));

          if (isJustLearned){
            dispatch(setKanjiJustLearned(true));
          }
    }

        // return isJustLearned

  }, [studyKanji.result]);


  const nextStudyKanji = () => {
    dispatch(retrieveKanjiStudy(kanjiProgress.level))
      .catch(e => {
        console.log(e);
      });
  };

  const nextStudyKanjiLearned = () => {
    dispatch(setKanjiJustLearned(false));
    dispatch(getKanjiProgress());
  };

  const newLevelStudy = () => {
    dispatch(retrieveKanjiStudy(kanjiProgress.level))
      .catch(e => {
        console.log(e);
      });

    dispatch(setKanjiProgressShowed())
            .catch(e => {
            console.log(e);
    });
};

  const setKanjiStudyResult = (answer) => {
    const studyHistoryRecord = formatStudyHistoryRecord(answer);

    dispatch(setKanjiStudyOptionsResult(studyHistoryRecord))
      .catch(e => {
        console.log(e);
      });
  };

  const setKanjiStudied = (answer) => {
    dispatch(setStudiedKanji())
      .catch(e => {
        console.log(e);
      });
  };

  function formatStudyHistoryRecord(answer){
    const record = {
      kanji: question.kanji,
      kanji_id: question.id,
      result: question.meaning === answer, 
      answer: answer, 
      correct_answer: question.meaning,
      quiz_type: "options"
    };

    return record;
  }


  if(studyKanji !== undefined & Object.keys(studyKanji).length !== 0){

    if (studyKanji.result && kanjiProgress.kanjiJustLearned)  {     
      return (<ItemLearnedCard 
              kanji={question.kanji}
              next={nextStudyKanjiLearned}
              levelCorrect={kanjiProgress.correct + 1}
              levelTotal={kanjiProgress.total}
              level={kanjiProgress.level}
              />)  
    } else if (kanjiProgress.showNewLevelCard)  {     
        return <NewLevelBanner 
                level={kanjiProgress.level}
                next={newLevelStudy} />   
      } else if(question.correct === 0 & !question.studied){
      return <NewStudyItemCard
                key={question.id}
                kanji={question.kanji}
                pronunciation1={"Onyomi: " + question.onyomi}
                pronunciation2={"Kunyomi: " + (question.kunyomi || "Not available")}
                meaning={question.meaning}
                setStudied={setKanjiStudied}
                />
    } else {
      return (<MultipleOptionCard 
                options={studyKanji.options}
                result={studyKanji.result}
                kanji={question.kanji}
                hiragana={question.onyomi}
                pct={question.pct}
                meaning={question.meaning}
                total={question.total}
                correct={question.correct}
                jlpt={question.jlpt}
                level={kanjiProgress.level}
                modelName={"Kanji"}
                next={nextStudyKanji}
                setResult={setKanjiStudyResult}/>)
    }
    
  } else {
    return (<Loading />)
  }
  
};

export default KanjiMultipleOption;