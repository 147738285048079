import React from "react";

const ItemProgressBox = props => {

    const getCardColorStatus = (pct, total) => {
        let textClass = [];
        
        if(pct === 0 & total > 0){
            textClass[0] = 'bg-danger';
            textClass[1] = 'text-white';
        } else if(pct === 0){
            textClass[0] = 'bg-secondary';
            textClass[1] = '';
        } else if(pct > 89 & total > 9){
            textClass[0] = 'bg-primary';
            textClass[1] = 'text-white';
        } else if(pct > 75){
            textClass[0] = 'bg-success';
            textClass[1] = 'text-white';
        } else if(pct > 49){
            textClass[0] = 'bg-warning';
            textClass[1] = 'text-white';
        } else {
            textClass[0] = 'bg-danger';
            textClass[1] = 'text-white';
        }
    
        return textClass;
      };

      let x = getCardColorStatus(parseInt(props.pct), props.total);
      let cardClass = x[0];
      let textClass = x[1];

    //   data-bs-toggle={props.dataBsToggle} data-bs-target={props.dataBsTarget}
    // if (props.liked){
    //     console.log(props.kanji)
    //   }

    return (
        <div className="col-xs-12 col-xl-3 col-md-6 col-sm-12 bottom-buffer" onClick={() => props.showDetails(props.item)} >
            <div className={"card " + cardClass}>
                <div className={"card-header border-light " + textClass}>
                    <div className="row">
                        <div className="col-6">
                            <span>{parseInt(props.pct) + "% - "}</span>
                            <small>{props.correct + "/" + props.total}</small>
                        </div>
                        <div className="col-6">
                            <div className="d-flex justify-content-end">
                                <span type="button" 
                                        className={"btn btn-outline-light btn-icon rounded-pill " + (props.liked ? "active" : "")  } 
                                        onClick={event => props.likeItem(event, props.id, props.liked)}>
                                    <i className="ai-bookmark"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card-body">
                    <div className={"text-center fs-4 " + textClass}>{props.pronunciation1}</div>
                    <h1 className={"display-3 text-center " + textClass }>{props.kanji === "" ? props.pronunciation1 : props.kanji}</h1>
                    <p className={"text-center fs-4 " + textClass}>{props.meaning}</p>
                    <span className="badge bg-light fs-sm">{props.level}</span>
                </div>
            </div> 
        </div>
        );
  
};

export default ItemProgressBox;