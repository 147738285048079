import React from 'react';

function Loading() {
  return (
    <div class="container">
    <div class="row justify-content-center pt-4">
      <div class="col-lg-7 col-md-9 col-sm-11">
        <h1 class="h2 pb-3">Forgot your password?</h1>
        <p class="fs-sm">Change your password in three easy steps. This helps to keep your new password secure.</p>
        <ul class="list-unstyled fs-sm pb-1 mb-4">
          <li><span class="text-primary fw-semibold me-1">1.</span>Fill in your email address below.</li>
          <li><span class="text-primary fw-semibold me-1">2.</span>We'll email you a temporary code.</li>
          <li><span class="text-primary fw-semibold me-1">3.</span>Use the code to change your password on our secure website.</li>
        </ul>
        <div class="bg-secondary rounded-3 px-3 py-4 p-sm-4">
          <form class="needs-validation p-2" novalidate>
            <div class="mb-3 pb-1">
              <label class="form-label" for="recovery-email">Enter your email address</label>
              <input class="form-control" id="recovery-email" />
              <div class="invalid-feedback">Please provide a valid email address!</div>
            </div>
            <button class="btn btn-primary" type="submit">Get new password</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Loading;
