import {
    RETRIEVE_VOCABULARY,
    RETRIEVE_VOCABULARY_BY_LEVEL,
    RETRIEVE_STUDY_VOCABULARY,
    SET_STUDY_VOCABULARY_OPTIONS_RESULT,
    SET_STUDIED_VOCABULARY,
    LIKE_VOCABULARY,
    UNLIKE_VOCABULARY,
    GET_VOCABULARY_PROGRESS,
    VOCABULARY_LEVEL_CHANGE_SHOWED,
    VOCABULARY_JUST_LEARNED
  } from "./types";
  import VocabularyDataService from "../services/VocabularyService";
  import LikeDataService from "../services/LikeService";

  export const retrieveVocabulary = () => async (dispatch) => {
    try {
      const res = await VocabularyDataService.getAll();
      dispatch({
        type: RETRIEVE_VOCABULARY,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const retrieveVocabularyByLevel = (level) => async (dispatch) => {
    try {
      const res = await VocabularyDataService.getByLevel(level);
      
      dispatch({
        type: RETRIEVE_VOCABULARY_BY_LEVEL,
        payload: {data: res.data, jlpt: level},
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const retrieveVocabularyStudy = (level) => async (dispatch) => {
    try {
      const res = await VocabularyDataService.getStudy(level);
      
      dispatch({
        type: RETRIEVE_STUDY_VOCABULARY,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const setVocabularyStudyOptionsResult = (studyRecord) => async (dispatch) => {
    try {
      const res = await VocabularyDataService.postStudyOptionsHistory(studyRecord);
      dispatch({
        payload: studyRecord.result,
        type: SET_STUDY_VOCABULARY_OPTIONS_RESULT,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const setStudiedVocabulary = () => async (dispatch) => {
    try {
      dispatch({
        type: SET_STUDIED_VOCABULARY,
        payload: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const likeVocabulary = (params) => async (dispatch) => {
    try {
      const res = await LikeDataService.like(params);
      
      dispatch({
        payload: res.data,
        type: LIKE_VOCABULARY,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const unlikeVocabulary = (params) => async (dispatch) => {
    try {
      const res = await LikeDataService.unlike(params);
      
      dispatch({
        payload: res.data,
        type: UNLIKE_VOCABULARY,
      });
    } catch (err) {
      console.log(err.response);
    }
  };

  export const getVocabularyProgress = () => async (dispatch) => {
    try {
      const res = await VocabularyDataService.getProgress();

      dispatch({
        payload: res.data,
        type: GET_VOCABULARY_PROGRESS,
      });

    } catch (err) {
      console.log(err.response);
    }
  };

  export const setVocabularyProgressShowed = () => async (dispatch) => {
    try {
      
      dispatch({
        payload: false,
        type: VOCABULARY_LEVEL_CHANGE_SHOWED,
      });

    } catch (err) {
      console.log(err.response);
    }
  };

  export const setVocabularyJustLearned = (isJustLearned) => async (dispatch) => {
    try {
      
      dispatch({
        payload: isJustLearned,
        type: VOCABULARY_JUST_LEARNED,
      });

    } catch (err) {
      console.log(err.response);
    }
  };