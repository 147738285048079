import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import KanjiBox from "./KanjiBox";

const PublicDashboard = () => {


  return (
    <div>
         <h1>Yokai Japanese</h1>
         <br />
         <br />
         <br />
         <figure>
            <blockquote className="blockquote">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
            </blockquote>
            <figcaption className="blockquote-footer">Juan Manuel Pasten</figcaption>
        </figure>
    </div>
  );
  
};
export default PublicDashboard;