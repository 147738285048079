import {
    RETRIEVE_LIKES,
    RETRIEVE_STUDY_LIKES,
    SET_LIKE_PRACTICE_ANSWER,
    SET_LIKE_PRACTICE_RESULT,
    NEXT_STUDY_LIKES
  } from "./types";
  import LikeDataService from "../services/LikeService";

  export const retrieveLikes = () => async (dispatch) => {
    try {
      const res = await LikeDataService.getAll();
      dispatch({
        type: RETRIEVE_LIKES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const retrieveStudyLikes = () => async (dispatch) => {
    try {
      const res = await LikeDataService.getAll();
      dispatch({
        type: RETRIEVE_STUDY_LIKES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const setStudyAnswer = (answer) => async (dispatch) => {
    try {
      dispatch({
        type: SET_LIKE_PRACTICE_ANSWER,
        payload: answer,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const setStudyResult = (result) => async (dispatch) => {
    try {
      // const res = await VerbDataService.postStudyVerbHistory(studyRecord);
      
      dispatch({
        type: SET_LIKE_PRACTICE_RESULT,
        payload: result,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const nextStudyLike = (index) => async (dispatch) => {
    try {
      dispatch({
        type: NEXT_STUDY_LIKES,
        payload: index,
      });
    } catch (err) {
      console.log(err);
    }
  };