import DashboardCard from './DashboardCard';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getVocabularyProgress } from "../../actions/vocabulary";
import { getKanjiProgress } from '../../actions/kanji';
import { getVerbProgress } from '../../actions/verbs';
import { retrieveLikes } from '../../actions/likes';

function LoggedDashboard() {

  // const level = useSelector(state => state.vocabulary.progress.level);
  // const {level, vocab_percentage} = useSelector(state => state.vocabulary.progress);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getKanjiProgress());
    dispatch(getVocabularyProgress());
    dispatch(getVerbProgress());
    dispatch(retrieveLikes());
  });

  const vocabularyLevel = useSelector(state => state.vocabulary.progress.level);
  const vocabularyPct = useSelector(state => state.vocabulary.progress.percentage);
  const verbLevel = useSelector(state => state.verb.progress.level);
  const verbPct = useSelector(state => state.verb.progress.percentage);
  const kanjiLevel = useSelector(state => state.kanji.progress.level);
  const kanjiPct = useSelector(state => state.kanji.progress.percentage);
  const likesCount = useSelector(state => state.likes.count)
  
    return (
    <div>
      <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-4 mb-4">
                  <DashboardCard 
                    title="Verbs" 
                    link="/verb/study" 
                    subText={"Level " + verbLevel}
                    topText={"JLPT"} 
                    progress={verbPct} 
                    btnText={"Study Now"} />
                </div>
                <div className="col-sm-12 col-md-4 mb-4">
                  <DashboardCard 
                    title="Kanji" 
                    link="/kanji/options" 
                    subText={"Level " + kanjiLevel}
                    topText={"JLPT"} 
                    progress={kanjiPct} 
                    btnText={"Study Now"} />
                </div>
                <div className="col-sm-12 col-md-4 mb-4">
                  <DashboardCard 
                    title="Vocab" 
                    link="/vocabulary/options" 
                    subText={"Level " + vocabularyLevel} 
                    topText={"JLPT"}
                    progress={vocabularyPct} 
                    btnText={"Study Now"} />
                </div>
            </div>
            <div className='row'>
              <div className="col-sm-12 col-md-4 mb-4">
                <DashboardCard 
                  title="Custom" 
                  link="/like/study" 
                  subText={likesCount + " items bookmarked"} 
                  topText={"JLPT"}
                  btnText={"Study Now"} />
              </div>
            </div>

            {/* <h2 class="h1 text-center pb-3 mb-3 mb-lg-4 mt-xxl-2">By Topic</h2> */}
            <div className='pb-5'>&nbsp;</div>
            <div className='row'>
              <div className="col-sm-12 col-md-4 mb-4">
                <DashboardCard title="Family" subText={"N5, N4"} topText={"Topic"} isDefault={true} btnText={"Coming Soon..."} />
              </div>
              <div className="col-sm-12 col-md-4 mb-4">
                <DashboardCard title="Month" subText={"N5, N4"} topText={"Topic"} isDefault={true} btnText={"Coming Soon..."} />
              </div>
              <div className="col-sm-12 col-md-4 mb-4">
                <DashboardCard title="Numbers" subText={"N5, N4"} topText={"Topic"} isDefault={true} btnText={"Coming Soon..."} />
              </div>
            </div>
            
        </div>
      
    </div>
    );
  }

export default LoggedDashboard;