import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";


const ProfileMenu = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutUser = event => {
      
      dispatch(logout())
        .then(response => {
            navigate('/login');
            // console.log("success action dispatched");
        })
        .catch(e => {
            console.log(e);
        });

    };
  
    // useEffect(() => {
    //     console.log(props);

        // if(isEmpty(props.loggedUser)){

        //     const currentUser = localStorage.getItem("loggedUser");
        //     if(currentUser){
        //         const currentUserObject = JSON.parse(currentUser);

        //         dispatch(setLoggedUser(currentUserObject))
        //         .then(response => {
        //             // console.log("success action dispatched");
        //         })
        //         .catch(e => {
        //             console.log(e);
        //     });

        //     } else {

        //     }
        // }

    // }, []);

  if (!isEmpty(props.loggedUser)) {
    // console.log(props.loggedUser);
    return (

        <div className="nav dropdown d-block order-lg-3 ms-4">
            <a href="#" className="d-flex nav-link p-0" data-bs-toggle="dropdown">
                <img src={props.avatarImage} className="rounded-circle" width="48" alt="Avatar"/>
                <div className="d-none d-sm-block ps-2">
                    <div className="fs-xs lh-1 opacity-60">Hello,</div>
                    <div className="fs-sm dropdown-toggle">{props.loggedUser.email}</div>
                </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end my-1" style={{width: 14 + 'rem'}}>
            <li>
            <Link to={"/user/"} className="dropdown-item d-flex align-items-center">
                <i className="bx bx-shopping-bag fs-base opacity-60 me-2"></i>
                Profile
                {/* <span className="bg-success rounded-circle mt-n2 ms-1" style={{width: 5}, { height: 5}}></span>
                <span className="ms-auto fs-xs text-muted">2</span> */}
                </Link>
            </li>
            <li className="dropdown-divider"></li>
            <li>
                <a className="dropdown-item d-flex align-items-center" onClick={logoutUser}>
                <i className="bx bx-log-out fs-base opacity-60 me-2"></i>
                Sign out
                </a>
            </li>
            </ul>
        </div>
            );
  } else {
    return (
        // <div class="d-flex align-items-center order-lg-3 ms-lg-auto">
        //     <a class="nav-link-style fs-sm text-nowrap" href="#modal-signin" data-bs-toggle="modal" data-view="#modal-signin-view">
        //         <i class="ai-user fs-xl me-2 align-middle">
        //             </i>Sign in</a><a class="btn btn-primary ms-grid-gutter d-none d-lg-inline-block" href="#modal-signin" data-bs-toggle="modal" data-view="#modal-signup-view">Sign up</a>
        //             </div>
        <div className="d-flex align-items-center order-lg-3 ms-lg-auto">
            <Link to={"/login"} className="nav-link-style fs-sm text-nowrap">
                <i className="ai-user fs-xl me-2 align-middle"></i>
                Sign In
            </Link>
            <Link to={"/register"} className="btn btn-primary ms-grid-gutter d-none d-lg-inline-block">
                Sign up
            </Link>
        </div>
        
    );
  }
};

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}


export default ProfileMenu;