import _  from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemProgressBox from "../base/ItemProgressBox";
import Modal from 'bootstrap/js/dist/modal';
import ScrollToTop from "react-scroll-up";

import{
  VERB_FORMS,
  VERB_LIKEABLE_TYPE
} from "../../globalConstants";

import {
  retrieveVerbByLevel,
  likeVerb,
  unlikeVerb
} from "../../actions/verbs";


const VerbStudyProgressList = () => {
  
  const verbList = useSelector(state => state.verb.all.list);
  const jlptLevel = useSelector(state => state.verb.all.jlpt);
  
  const dispatch = useDispatch();

  const [selectedVerb, setSelectedVerb] = useState("");
  
  useEffect(() => {
    dispatch(retrieveVerbByLevel(jlptLevel));
  }, [jlptLevel]);

  const handleJlptChange = (event) => {
    dispatch(retrieveVerbByLevel(event.target.value))
      .catch(e => {
        console.log(e);
      });
  };

  const showVerbDetails = (verb) => {
    setSelectedVerb(verb);
    
    var myModal = new Modal(document.getElementById('modalId'), {})
    myModal.show();
  };

  const toggleVerbLike = (event, id, liked) => {
    event.stopPropagation();

    var like_params = {
      like: {
        likeable_id: id, 
        likeable_type: VERB_LIKEABLE_TYPE
      }
    };
    
    if(liked){
      dispatch(unlikeVerb(like_params))
        .catch(e => {
        console.log(e);
      });
    } else {
      dispatch(likeVerb(like_params))
        .catch(e => {
        console.log(e);
      });
    }

  };

  const formatFormLabel = (verbForm) => {
    return verbForm.replaceAll("_", " ");
  };

  let cardClass = "";
  let textClass = "";

  return (
    <div>

        

        <h1>Verb List</h1>
        
        <div className="container">
          {/* <div className="row">
            <div className="col-2 col-sm-4 col-xl-2">
              <select className="form-select form-select-sm" aria-label=".form-select-sm" value={jlptLevel} onChange={handleJlptChange}>
                  <option value="4">JLPT 4</option>
                  <option value="5">JLPT 5</option>
              </select>
            </div>
          </div> */}
          <br/>
          <div className="row">
            {verbList && verbList.map((verb, index) => (
              
                  <ItemProgressBox 
                  key={verb.id}
                  id={verb.id}
                  pct={verb.pct}
                  correct={verb.correct}
                  total={verb.total}
                  kanji={verb.kanji}
                  pronunciation1={verb.hiragana}
                  pronunciation2=""
                  meaning={verb.meaning}
                  showDetails={showVerbDetails}
                  item={verb}
                  likeItem={toggleVerbLike}
                  liked={verb.liked}
                  level={verb.level}
                  />
                
            ))}
          </div>
        </div>

        <ScrollToTop showUnder={160} style={{zIndex:'20'}}>
          <button type="button" className="btn btn-dark btn-icon">
            <i className="ai-arrow-up"></i>
          </button>
        </ScrollToTop>
        
        <div className="modal fade" id="modalId" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">

              {selectedVerb &&
              <div className={"card " + cardClass}>
                <div className={"card-header border-light " + textClass}>
                    {parseInt(selectedVerb.pct) + "% - "}
                    <small>{selectedVerb.correct + "/" + selectedVerb.total}</small>
                </div>
                <div className="card-body">
                    <h1 className={"display-3 text-center " + textClass }>{selectedVerb.kanji}</h1>
                    <p className={"text-center " + textClass}>{selectedVerb.pronunciation1}</p>
                    <p className={"text-center fs-4 " + textClass}>{selectedVerb.meaning}</p>

                    <div className="container">
                      <div className="row">
                        {VERB_FORMS.map((form, index) => (
                          
                            <div className="col-6" key={form}>
                              <div className="fs-sm mb-2"><strong className='text-capitalize'>{formatFormLabel(form)}</strong>&nbsp;{ parseInt(selectedVerb[form + "_pct"]) + "%"}</div>
                              <div className="progress mb-3" style={{"height": "4px"}}>
                                <div className="progress-bar bg-success" role="progressbar" style={{"width": (parseInt(selectedVerb[form + "_pct"]) + "%")}} aria-valuenow={parseInt(selectedVerb[form + "_pct"])} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                          
                        ))}
                      </div>
                    </div>

                </div>
              </div> 
              }
              
            </div>
          </div>
        </div>

    </div>
    
  );
  
};
export default VerbStudyProgressList;