import http from "../http-common";
import store from '../store';

let state = store.getState();
let token = state.auth.user.access_token;

const getAll = () => {
    return http.get("/vocabulary");
};

const getByLevel = level => {
  let state = store.getState();
  let token = state.auth.user.access_token;

  return http.get(`/api/vocabularies?jlptlevel=${level}`, {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
};

const getStudy = level => {
  let state = store.getState();
  let token = state.auth.user.access_token;
  
  if(token != undefined){
    return http.get(`/api/vocabularies/study`, {
      params: {
        level: level
      },
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    })
    .catch(err => {
      return err.response;
    })
  } else {
    return http.get(`/api/vocabularies/open_study`)
    .catch(err => {
      return err.response;
    })
  }
};

const postStudyOptionsHistory = data => {
  let state = store.getState();
  let token = state.auth.user.access_token;
  
  if(token != undefined){
    return http.post("/api/vocabulary_studies", data, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    });
  }
};

function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const getProgress = () => {
  let state = store.getState();
  let token = state.auth.user.access_token;

  return http.get(`/api/vocabularies/progress`, {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
};

const VocabularyService = {
  getAll,
  getByLevel,
  getStudy,
  postStudyOptionsHistory,
  getProgress
};
export default VocabularyService;
