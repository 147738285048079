import React from 'react';

function Footer() {
  return (
    <footer className="footer bg-dark pt-5 pt-md-6">
    <div className="container pt-3 pt-md-0">
       <div className="row pb-3">
        <div className="col-md-4 mt-n2 pb-3 pb-md-0 mb-4">
            <a className="d-block mb-3" href="/" style={{width: 153}}>
              妖怪
            </a>
          <p className="fs-sm text-light opacity-60 pb-2 pb-sm-3">Japanese learning tools, designed to help you learn JLPT grammar, vocabulary and Kanji.</p><a className="btn-social bs-outline bs-facebook bs-light bs-lg me-2 mb-2" href="#"><i className="ai-facebook"></i></a><a className="btn-social bs-outline bs-twitter bs-light bs-lg me-2 mb-2" href="#"><i className="ai-twitter"></i></a><a className="btn-social bs-outline bs-instagram bs-light bs-lg me-2 mb-2" href="#"><i className="ai-instagram"></i></a><a className="btn-social bs-outline bs-google bs-light bs-lg me-2 mb-2" href="#"><i className="ai-google"></i></a>
        </div>
        <div className="col-md-2 col-sm-4 ms-auto pb-1 mb-4">
          <div className="widget widget-light">
            <h4 className="widget-title">Company</h4>
            <ul>
              <li><a className="widget-link" href="#">About</a></li>
              <li><a className="widget-link" href="#">Careers</a></li>
              <li><a className="widget-link" href="#">Press</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 pb-1 mb-4">
          <div className="widget widget-light">
            <h4 className="widget-title">Communities</h4>
            <ul>
              <li><a className="widget-link" href="#">For artists</a></li>
              <li><a className="widget-link" href="#">Developers</a></li>
              <li><a className="widget-link" href="#">Brands</a></li>
              <li><a className="widget-link" href="#">Investors</a></li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-3 col-sm-4 mb-4">
          <div className="widget widget-light">
            <h4 className="widget-title">Our news</h4>
            <ul>
              <li><a className="widget-link" href="#">Latest news</a></li>
              <li><a className="widget-link" href="#">Updates</a></li>
              <li><a className="widget-link" href="#">Campaigns</a></li>
            </ul>
          </div>
        </div>
      </div> 
      <hr className="my-0 border-light" />
      <div className="row align-items-center mt-3 py-4">
        <div className="col-md-6 order-md-2 text-md-end mb-3">
          <ul className="list-inline fs-sm mb-0">
            <li className="list-inline-item"><a className="nav-link-style nav-link-light" href="#">Support</a></li>
            <li className="list-inline-item"><a className="nav-link-style nav-link-light" href="#">Contacts</a></li>
            <li className="list-inline-item"><a className="nav-link-style nav-link-light" href="#">Terms &amp; Conditions</a></li>
          </ul>
        </div>
        <div className="col-md-6 order-md-1 mb-3">
          <p className="fs-sm mb-0"><span className="text-light opacity-50 me-1">© All rights reserved. Made by</span><a className="nav-link-style nav-link-light" href="#" target="_blank" rel="noopener">Humphryman</a></p>
        </div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;
