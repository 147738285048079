import _  from "lodash";
import {
    RETRIEVE_KANJI, 
    RETRIEVE_KANJI_BY_LEVEL,
    RETRIEVE_STUDY_KANJI,
    SET_STUDIED_KANJI,
    SET_STUDY_KANJI_OPTIONS_RESULT,
    LIKE_KANJI,
    UNLIKE_KANJI,
    GET_KANJI_PROGRESS,
    KANJI_LEVEL_CHANGE_SHOWED,
    KANJI_JUST_LEARNED
  } from "../actions/types";

  import{
    FORMAT_PROGRESS,
    DEFAULT_KANJI_OPTIONS
    } from "../globalConstants";
  
  const initialState = {
    all:{
      list:[], 
      jlpt:5
    }, 
    study:{}, 
    progress:{
      level:1, 
      percentage: 0,
      correct: 0,
      total: 0
    }
  };

  function formatStudyKanji(payload){

    let studied = payload[0].correct > 0;
    payload[0].studied = studied;

    let kanjiOptions = DEFAULT_KANJI_OPTIONS();
    let allOptions = payload.concat(kanjiOptions);

    const studyObj = {
      question: payload[0],
      options: _.shuffle([allOptions[0].meaning, allOptions[1].meaning, allOptions[2].meaning, allOptions[3].meaning])
    };

    return studyObj;
  }

function kanjiReducer(kanji = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case RETRIEVE_KANJI:
        return Object.assign({}, kanji, {
          all: payload
        })
    case RETRIEVE_KANJI_BY_LEVEL:
        return Object.assign({}, kanji, {
          all: {list: payload.data, jlpt:payload.jlpt}
        })
    case RETRIEVE_STUDY_KANJI:
        return Object.assign({}, kanji, {
          study: formatStudyKanji(payload)
        })
    case SET_STUDY_KANJI_OPTIONS_RESULT:
        const studyObj = Object.assign({}, kanji.study, { result: payload })

        return Object.assign({}, kanji, {
          study: studyObj
        })
    case SET_STUDIED_KANJI: 
        const questionObj = Object.assign({}, kanji.study.question, { studied: payload })
        const studiedObj = Object.assign({}, kanji.study, { question: questionObj})

        return Object.assign({}, kanji, {
          study: studiedObj
        })
    case LIKE_KANJI: 
        var kanji_list = kanji.all.list;
  
        //refactor this
        kanji_list.forEach((element, index) => {
          if(element.id === payload.likeable_id) {
            kanji_list[index].liked = true;
          }
        });
  
        var kanji_all_obj = Object.assign({}, kanji.all, { list: [...kanji_list] })
  
        return Object.assign({}, kanji, {
          all: kanji_all_obj
        })
    case UNLIKE_KANJI: 
      var kanji_list = kanji.all.list;

      //refactor this
      kanji_list.forEach((element, index) => {
        if(element.id === payload.likeable_id) {
          kanji_list[index].liked = false;
        }
      });

      var kanji_all_obj = Object.assign({}, kanji.all, { list: [...kanji_list] })


      return Object.assign({}, kanji, {
        all: kanji_all_obj
      })

    case GET_KANJI_PROGRESS:
      var progress_obj = Object.assign({}, kanji.progress, FORMAT_PROGRESS(payload))

      return Object.assign({}, kanji, {
        progress: progress_obj
      })
    case KANJI_LEVEL_CHANGE_SHOWED:
      var progress_obj = Object.assign({}, kanji.progress, { showNewLevelCard: payload })

      return Object.assign({}, kanji, {
        progress: progress_obj
      })
    case KANJI_JUST_LEARNED:
      var progress_obj = Object.assign({}, kanji.progress, { kanjiJustLearned: payload })

      return Object.assign({}, kanji, {
        progress: progress_obj
      })
    default:
      return kanji;
    }
  };

  export default kanjiReducer;