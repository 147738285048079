import { combineReducers } from "redux";
import users from "./users";
import auth from './auth';
import kanji from './kanji';
import vocabulary from './vocabulary'
import verb from './verb'
import settings from './settings'
import likes from './likes'

export default combineReducers({
  auth,
  users,
  kanji,
  vocabulary,
  verb,
  settings,
  likes
});