export const CREATE_USER = "CREATE_USER";
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_ALL_USERS = "DELETE_ALL_USERS";

export const LOGIN = "LOGIN";
export const LOGINERROR = "LOGINERROR";
export const SIGNUP = "SIGNUP";
export const SETLOGGEDUSER = "SETLOGGEDUSER";
export const LOGOUT = "LOGOUT";

export const RETRIEVE_KANJI = "RETRIEVE_KANJI";
export const RETRIEVE_KANJI_BY_LEVEL = "RETRIEVE_KANJI_BY_LEVEL";
export const RETRIEVE_STUDY_KANJI = "RETRIEVE_STUDY_KANJI";
export const SET_STUDY_KANJI_OPTIONS_RESULT = "SET_STUDY_KANJI_OPTIONS_RESULT";
export const SET_STUDIED_KANJI = "SET_STUDIED_KANJI";
export const LIKE_KANJI = "LIKE_KANJI";
export const UNLIKE_KANJI = "UNLIKE_KANJI";
export const GET_KANJI_PROGRESS = "GET_KANJI_PROGRESS";
export const KANJI_LEVEL_CHANGE_SHOWED = "KANJI_LEVEL_CHANGE_SHOWED";
export const KANJI_JUST_LEARNED = "KANJI_JUST_LEARNED";


export const RETRIEVE_VOCABULARY = "RETRIEVE_VOCABULARY";
export const RETRIEVE_VOCABULARY_BY_LEVEL = "RETRIEVE_VOCABULARY_BY_LEVEL";
export const RETRIEVE_STUDY_VOCABULARY = "RETRIEVE_STUDY_VOCABULARY";
export const SET_STUDY_VOCABULARY_OPTIONS_RESULT = "SET_STUDY_VOCABULARY_OPTIONS_RESULT";
export const SET_STUDIED_VOCABULARY = "SET_STUDIED_VOCABULARY";
export const LIKE_VOCABULARY = "LIKE_VOCABULARY";
export const UNLIKE_VOCABULARY = "UNLIKE_VOCABULARY";
export const GET_VOCABULARY_PROGRESS = "GET_VOCABULARY_PROGRESS";
export const VOCABULARY_LEVEL_CHANGE_SHOWED = "VOCABULARY_LEVEL_CHANGE_SHOWED";
export const VOCABULARY_JUST_LEARNED = "VOCABULARY_JUST_LEARNED";


export const RETRIEVE_PRACTICE_VERB = "RETRIEVE_PRACTICE_VERB";
export const SET_PRACTICE_VERB_RESULT = "SET_PRACTICE_VERB_RESULT";
export const SET_PRACTICE_VERB_RESPONSE = "SET_PRACTICE_VERB_RESPONSE";
export const RETRIEVE_VERB_BY_LEVEL = "RETRIEVE_VERB_BY_LEVEL";
export const SET_STUDIED_VERB = "SET_STUDIED_VERB";
export const LIKE_VERB = "LIKE_VERB";
export const UNLIKE_VERB = "UNLIKE_VERB";
export const GET_VERB_PROGRESS = "GET_VERB_PROGRESS";


export const SET_SHOW_HIRANAGANA = "SET_SHOW_HIRANAGANA";
export const SET_JLPT_LEVEL = "SET_JLPT_LEVEL";

export const RETRIEVE_LIKES = "RETRIEVE_LIKES";
export const RETRIEVE_STUDY_LIKES = "RETRIEVE_STUDY_LIKES";
export const SET_LIKE_PRACTICE_ANSWER = "SET_LIKE_PRACTICE_ANSWER";
export const SET_LIKE_PRACTICE_RESULT = "SET_LIKE_PRACTICE_RESULT";
export const NEXT_STUDY_LIKES = "NEXT_STUDY_LIKES";
