import React from 'react';
import NotFoundImage from '../assets/images/404-text.svg';
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="container d-flex flex-column justify-content-center pt-5 mt-n6" style={{ flex: 1 }}>
        <div className="pt-7 pb-5">
          <div className="text-center mb-2 pb-4">
            <h1 className="mb-5">
              <img className="d-inline-block" src={NotFoundImage} alt="Error 404" />
              <span className="visually-hidden">Error 404</span>
            </h1>
            <h2>Page not found!</h2>
            <p className="pb-2">It seems we can’t find the page you are looking for.</p>
            <Link className='btn btn-translucent-primary me-3' to={"/"}> Go to homepage </Link>
            <span>Or try</span>
          </div>
          <div className="input-group mx-auto" style={{maxWidth: 390}}>
            <i className="ai-search position-absolute top-50 start-0 translate-middle-y ms-3"></i>
            <input className="form-control rounded" type="text" placeholder="Search" />
          </div>
        </div>
      </div>
  );
}

export default NotFound;
