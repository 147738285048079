import React, { useEffect } from "react";
import _  from "lodash";

const StudyResultText = props => {

    const getTitle = () => {
        return props.result ? "Success" : "Incorrect";
      };

    let textClass = (props.result ? "alert-success" : "alert-danger"); 
    // let message = _.startCase(props.correctAnswer) + " is the answer for " + props.kanji + " (" + props.meaning + ").";
    let title = (props.result ? "Success" : "Incorrect")

    return (
        <div className="container">
                <div className="row">
                    <div role="alert" className={"fade alert show " + textClass}>
                        <div className="alert-heading h4">{title}</div>
                        <p>
                            <span className="fs-5">{_.startCase(props.correctAnswer)}</span>
                            &nbsp; is the answer for &nbsp;
                            <span className="fs-5">{props.kanji}</span>
                            <span className="fs-5">&nbsp;({props.meaning})</span>
                        </p>
                    </div>
                </div>
            </div>
        );
  
};

export default StudyResultText;