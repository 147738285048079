import {
    SIGNUP,
    LOGIN,
    LOGINERROR,
    LOGOUT,
  } from "../actions/types";
  const initialState = {user: {}, error: null};

function authReducer(loggedUser = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case SIGNUP:
        return payload;
      case LOGIN:
        return Object.assign({}, loggedUser, {
          user: payload
        })
      case LOGINERROR:
        return Object.assign({}, loggedUser, {
          error: payload
        })
      case LOGOUT:
        return initialState;
      default:
        return loggedUser;
    }
  };

  export default authReducer;