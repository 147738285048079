import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import vocabularyReducer from "../../reducers/vocabulary";
import Loading from '../Loading';
import Settings from '../Settings';
import StudyResultText from './StudyResultText';

const VocabularyMultipleOptionCard = (props) => {
  const options = props.options;
  const result = props.result;

  const nextButtonRef = useRef();
  
  const showHiragana = useSelector(state => state.settings.showHiragana);

  useEffect(() => {

    if(props.result != null){
        nextButtonRef.current.focus();
    }
  }, [props.result]);

  function formatMaxLenght(text){
    if(text.length > 20){
        let displayText = text.slice(0, 23);
        return displayText + '...';
    } else {
        return text;
    }
  }

  function formatfontSize(text){
    const length = text.length;
    let textClass = '';
    
    switch (true) {
    case (length < 9):
        textClass = 'fs-lg';
        break;
    case (length < 14):
        break;
    case (length < 18):
        textClass = 'fs-sm';
        break;
    default:
        textClass = 'fs-xs';
        break;
    }

    return textClass;
  }

  if(props.kanji !== undefined){
    let rating = parseInt(props.pct);
    
    return (
        
        <div> 
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-12 text-center mb-3">
                        <h1>{props.modelName} <span className="badge bg-secondary">Level {props.level}</span></h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-lg-6 offset-lg-3 col-12">
                        <div className="card">
                            <h5 className="card-header">
                            What is the meaning of:
                            </h5>
                            <div className="card-body">
                                
                                <div className="container">

                                    <div className="row">
                                        <div className="col">
                                            <Settings />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <span className="badge bg-secondary float-end">JLPT N{props.jlpt}</span>
                                        </div>
                                    </div>
                                    <h1 className="display-4">{props.kanji || props.hiragana}</h1>
                                    
                                    {showHiragana && <p className="card-title h5">{props.hiragana}</p>}
                                
                                    {result == null &&
                                    <div className="row top-buffer">
                                        <div className="container">
                                            
                                            <div className="row">
                                                <div className="col-xs-6 col-md-6 d-grid bottom-buffer">
                                                    <button
                                                        type="button"
                                                        className={'btn btn-primary btn-lg text-capitalize ' + formatfontSize(options[0])}
                                                        onClick={ () => props.setResult(options[0]) }
                                                        >
                                                        {formatMaxLenght(options[0])}
                                                    </button>
                                                
                                                </div>
                                                <div className="col-xs-6 col-md-6 d-grid bottom-buffer">
                                                    <button
                                                        type="button"
                                                        className={'btn btn-primary btn-lg text-capitalize ' + formatfontSize(options[1])}
                                                        onClick={ () => props.setResult(options[1]) }
                                                        >
                                                            {formatMaxLenght(options[1])}
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-xs-6 col-md-6 d-grid bottom-buffer">
                                                    <button
                                                        type="button"
                                                        className={'btn btn-primary btn-lg text-capitalize ' + formatfontSize(options[2])}
                                                        onClick={ () => props.setResult(options[2]) }
                                                        >
                                                            {formatMaxLenght(options[2])}
                                                    </button>
                                                </div>
                                                <div className="col-xs-6 col-md-6 d-grid bottom-buffer">
                                                    <button
                                                        type="button"
                                                        className={'btn btn-primary btn-lg text-capitalize ' + formatfontSize(options[3])}
                                                        onClick={ () => props.setResult(options[3]) }
                                                    >
                                                        {formatMaxLenght(options[3])}
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    }


                                    {props.result != null &&
                                    <div>
                                        <StudyResultText result={props.result} kanji={props.kanji} correctAnswer={props.meaning} meaning={props.hiragana} />

                                        <button
                                            type="submit"
                                            className="btn btn-outline-primary float-end"
                                            onClick={props.next}
                                            ref={nextButtonRef}
                                        >Next Question
                                        </button>
                                    </div>
                                    }

                                </div>
                            </div>
                            <div className="card-footer fs-sm text-center">
                                {(props.total !== 0 && props.total !== undefined) &&
                                    <div className="text-muted">{rating}% Rating</div>
                                }

                                {props.total === 0 &&
                                    <div><strong>New Word!</strong></div>
                                }
                            </div>

                        </div>
                    </div>
                    {/* <div className="col-lg-3 col-sm-6 col-12">
                        <Settings />
                    </div> */}
                </div>
            </div>
            
        </div>
    );
    } else {
        return (<Loading />)
    }
  
};
export default VocabularyMultipleOptionCard;