import {
    SET_SHOW_HIRANAGANA,
    SET_JLPT_LEVEL,
  } from "./types";

  export const setJlptLevel = (level) => async (dispatch) => {
    try {
      dispatch({
        type: SET_JLPT_LEVEL,
        payload: level,
      });
    } catch (err) {
      console.log(err);
    }
  };

  export const setShowHiragana = (show) => async (dispatch) => {
    try {
      dispatch({
        type: SET_SHOW_HIRANAGANA,
        payload: show,
      });
    } catch (err) {
      console.log(err);
    }
  };
  

  