import http from "../http-common";
import store from '../store';


const getByLevel = level => {
  let state = store.getState();
  let token = state.auth.user.access_token;
  
  return http.get(`/api/verbs?JlptLevel=${level}`, {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
};

const getStudyVerb = () => {
  let state = store.getState();
  let token = state.auth.user.access_token;

  if(token != undefined){
    return http.get("/api/verbs/study", {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    });
  } else {
    return http.get("/api/verbs/open_study");
  }
};

const postStudyVerbHistory = data => {
  let state = store.getState();
  let token = state.auth.user.access_token;

  if(token != undefined){
    return http.post("/api/verb_studies", data, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    });
  }
};

const getProgress = () => {
  let state = store.getState();
  let token = state.auth.user.access_token;

  return http.get(`/api/verbs/progress`, {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  });
};

const VerbService = {
    getStudyVerb,
    postStudyVerbHistory,
    getByLevel,
    getProgress
};
export default VerbService;
