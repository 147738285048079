import axios from "axios";

// axios.defaults.withCredentials = true;
// baseURL: 'https://nihongoapi.humberto.dev',
// baseURL: "http://localhost:3000",
// Authorization: `Bearer ${sessionToken}`,

const instance = axios.create({
  baseURL: "https://yokaiapi.humberto.dev",
  // baseURL: "http://localhost:3000",
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*'
  }
});

export default instance;