import http from "../http-common";
import store from '../store';

let state = store.getState();
let token = state.auth.user.access_token;

// console.log(process.env.REACT_APP_CLIENT_ID);
// console.log(process.env.REACT_APP_CLIENT_SECRET);

const login = (email, password) => {

  var data = {
    grant_type: "password",
    email: email,
    password: password,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET
  }
  return http.post("/api/oauth/token", data);
};

const logout = () => {
  
  var data = {
    token: token,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET
  }
  return http.post("/api/oauth/revoke", data);
};

const signUp = (email, password) => {

  var data = {
    email: email,
    password: password,
    client_id: process.env.REACT_APP_CLIENT_ID
  }
  console.log(data);

  return http.post("/api/users", data);
};

const AuthService = { 
  login,
  logout,
  signUp
};
export default AuthService;